/* DonationRoute.css */

.donation-route-container {
    max-width: 800px;
    margin: 0 auto;
    padding: var(--spacing-2xl);
  }
  
  .back-navigation {
    margin-bottom: var(--spacing-xl);
  }
  
  .back-button {
    display: inline-flex;
    align-items: center;
    gap: var(--spacing-sm);
    padding: var(--spacing-md) var(--spacing-lg);
    background-color: var(--color-white);
    border: 1px solid var(--color-border);
    border-radius: var(--radius-md);
    color: var(--color-text-primary);
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: all var(--transition-normal);
  }
  
  .back-button:hover {
    background-color: var(--color-background);
    border-color: var(--color-border-hover);
    transform: translateY(-1px);
    box-shadow: var(--shadow-sm);
  }
  
  .back-button svg {
    font-size: 1rem;
    color: var(--color-text-secondary);
    transition: transform var(--transition-normal);
  }
  
  .back-button:hover svg {
    transform: translateX(-4px);
    color: var(--color-text-primary);
  }
  
  /* Loading state */
  .loading-container {
    background: var(--color-white);
    border-radius: var(--radius-lg);
    box-shadow: var(--shadow-md);
    padding: var(--spacing-2xl);
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 400px;
  }
  
  .loading-spinner {
    width: 48px;
    height: 48px;
    border: 3px solid var(--color-border);
    border-top-color: var(--status-pending);
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  /* Error and Not Found states */
  .error-container,
  .not-found-container {
    background: var(--color-white);
    border-radius: var(--radius-lg);
    box-shadow: var(--shadow-md);
    padding: var(--spacing-2xl);
    text-align: center;
    margin-top: var(--spacing-xl);
  }
  
  .error-container {
    border: 1px solid rgba(239, 68, 68, 0.2);
    color: var(--status-rejected);
  }
  
  .not-found-container {
    border: 1px solid var(--color-border);
    color: var(--color-text-secondary);
  }
  
  /* Standalone donation details container */
  .donation-details-standalone {
    background: var(--color-white);
    border-radius: var(--radius-lg);
    box-shadow: var(--shadow-md);
    width: 100%;
  }
  
  /* Responsive adjustments */
  @media (max-width: 850px) {
    .donation-route-container {
      padding: var(--spacing-xl);
    }
  }
  
  @media (max-width: 640px) {
    .donation-route-container {
      padding: var(--spacing-lg);
    }
    
    .back-button {
      width: 100%;
      justify-content: center;
    }
  }