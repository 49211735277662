/* Design System - Custom Properties */
:root {
  /* Colors - Base */
  --primary-50: #eff6ff;
  --primary-100: #dbeafe;
  --primary-200: #bfdbfe;
  --primary-300: #93c5fd;
  --primary-400: #60a5fa;
  --primary-500: #3b82f6;
  --primary-600: #2563eb;
  --primary-700: #1d4ed8;
  
  /* Success Colors */
  --success-50: #ecfdf5;
  --success-500: #10b981;
  --success-600: #059669;
  
  /* Error Colors */
  --error-50: #fef2f2;
  --error-500: #ef4444;
  --error-600: #dc2626;
  
  /* Neutral Colors */
  --neutral-50: #f9fafb;
  --neutral-100: #f3f4f6;
  --neutral-200: #e5e7eb;
  --neutral-300: #d1d5db;
  --neutral-400: #9ca3af;
  --neutral-500: #6b7280;
  --neutral-600: #4b5563;
  --neutral-700: #374151;
  --neutral-800: #1f2937;
  --neutral-900: #111827;
  
  /* Semantic Colors */
  --background-page: var(--neutral-100);
  --background-card: var(--neutral-50);
  --background-white: #ffffff;
  --text-primary: var(--neutral-900);
  --text-secondary: var(--neutral-600);
  --text-disabled: var(--neutral-400);
  --border-color: var(--neutral-200);
  --border-focus: var(--primary-500);
  
  /* Overlay & Shadow */
  --overlay-bg: rgba(0, 0, 0, 0.6);
  --shadow-sm: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --shadow-md: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --shadow-lg: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --shadow-focus: 0 0 0 3px var(--primary-200);
  
  /* Border Radius */
  --radius-sm: 0.375rem;
  --radius-md: 0.5rem;
  --radius-lg: 0.75rem;
  --radius-xl: 1rem;
  
  /* Spacing */
  --spacing-1: 0.25rem;
  --spacing-2: 0.5rem;
  --spacing-3: 0.75rem;
  --spacing-4: 1rem;
  --spacing-5: 1.25rem;
  --spacing-6: 1.5rem;
  --spacing-8: 2rem;
  --spacing-10: 2.5rem;
  
  /* Animation */
  --transition-fast: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  --transition-normal: 200ms cubic-bezier(0.4, 0, 0.2, 1);
  --transition-slow: 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

/* Base Layout Styles */
.assign-donation-container {
  background-color: var(--background-white);
  border-radius: var(--radius-xl);
  box-shadow: var(--shadow-lg);
  padding: var(--spacing-10);
  margin: var(--spacing-8) auto;
  width: auto;
  position: relative;
}

/* Header Styles */
.assign-donation-container h3 {
  font-size: 1.875rem;
  font-weight: 700;
  color: var(--text-primary);
  margin-bottom: var(--spacing-10);
  padding-bottom: var(--spacing-6);
  border-bottom: 2px solid var(--border-color);
  display: flex;
  align-items: center;
  gap: var(--spacing-4);
}

.assign-donation-container h3 svg {
  color: var(--primary-500);
}

/* Pallet Groups Section */
.pallet-group-assignments {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-6);
}

.pallet-group-assignments h4 {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--text-primary);
  margin-bottom: var(--spacing-6);
}

/* Pallet Group Card */
.pallet-group-assignment {
  background: var(--background-white);
  border: 1px solid var(--border-color);
  border-radius: var(--radius-lg);
  padding: var(--spacing-8);
  display: grid;
  gap: var(--spacing-6);
  transition: var(--transition-normal);
  box-shadow: var(--shadow-sm);
}

.pallet-group-assignment:hover {
  border-color: var(--primary-200);
  box-shadow: var(--shadow-md);
  transform: translateY(-1px);
}

/* Group Header */
.group-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.group-header h5 {
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--text-primary);
  display: flex;
  align-items: center;
  gap: var(--spacing-2);
}

.group-header h5 svg {
  color: var(--primary-500);
}

/* Pallet Quantity Controls */
.pallet-quantity {
  display: flex;
  align-items: center;
  gap: var(--spacing-4);
  background: var(--neutral-50);
  padding: var(--spacing-3);
  border-radius: var(--radius-lg);
  width: fit-content;
}

.quantity-btn {
  background: var(--background-white);
  border: 1px solid var(--border-color);
  border-radius: var(--radius-md);
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: var(--transition-normal);
  color: var(--text-secondary);
}

.quantity-btn:hover:not(:disabled) {
  background: var(--primary-500);
  border-color: var(--primary-500);
  color: white;
  transform: translateY(-1px);
}

.quantity-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background: var(--neutral-100);
}

.pallet-quantity span {
  font-weight: 600;
  color: var(--text-primary);
  min-width: 5rem;
  text-align: center;
}

/* Assign Button */
.assign-button {
  padding: var(--spacing-4) var(--spacing-6);
  border: none;
  border-radius: var(--radius-lg);
  font-weight: 600;
  cursor: pointer;
  transition: var(--transition-normal);
  background: var(--primary-500);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-3);
  box-shadow: var(--shadow-sm);
}

.assign-button:hover:not(:disabled) {
  background: var(--primary-600);
  transform: translateY(-2px);
  box-shadow: var(--shadow-md);
}

.assign-button.assigned {
  background: var(--success-500);
}

.assign-button.assigned:hover {
  background: var(--success-600);
}

.assign-button.selecting {
  background: var(--primary-400);
  animation: pulse 2s infinite;
}

/* Quote Upload */
.quote-upload {
  display: flex;
  align-items: center;
  gap: var(--spacing-4);
}

.quote-upload label {
  flex: 1;
  padding: var(--spacing-4) var(--spacing-6);
  background: var(--neutral-50);
  border: 2px dashed var(--border-color);
  border-radius: var(--radius-lg);
  cursor: pointer;
  transition: var(--transition-normal);
  display: flex;
  align-items: center;
  gap: var(--spacing-3);
  font-weight: 500;
  color: var(--text-secondary);
}

.quote-upload label:hover {
  border-color: var(--primary-500);
  background: var(--primary-50);
  color: var(--primary-600);
}

.quote-upload label.has-file {
  background: var(--success-500);
  border-style: solid;
  color: white;
}

.quote-upload input[type="file"] {
  display: none;
}

/* Quote Price Input */
.quote-price {
  position: relative;
  display: flex;
  align-items: center;
}

.quote-price input {
  width: 100%;
  padding: var(--spacing-4) var(--spacing-4) var(--spacing-4) var(--spacing-10);
  border: 1px solid var(--border-color);
  border-radius: var(--radius-lg);
  font-size: 1rem;
  transition: var(--transition-normal);
  color: var(--text-primary);
}

.quote-price input:focus {
  border-color: var(--primary-500);
  box-shadow: var(--shadow-focus);
  outline: none;
}

.quote-price svg {
  position: absolute;
  left: var(--spacing-4);
  color: var(--text-secondary);
}

/* Add Group Button */
.add-group-btn {
  padding: var(--spacing-4) var(--spacing-6);
  background: var(--neutral-50);
  border: 2px dashed var(--border-color);
  border-radius: var(--radius-lg);
  color: var(--text-secondary);
  font-weight: 600;
  cursor: pointer;
  transition: var(--transition-normal);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-3);
  margin-top: var(--spacing-4);
}

.add-group-btn:hover:not(:disabled) {
  border-color: var(--primary-500);
  color: var(--primary-600);
  background: var(--primary-50);
}

.add-group-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Summary Section */
.summary-section {
  background: var(--neutral-50);
  border-radius: var(--radius-lg);
  padding: var(--spacing-8);
  margin-top: var(--spacing-10);
  border: 1px solid var(--border-color);
}

.total-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-4) var(--spacing-6);
  background: var(--background-white);
  border-radius: var(--radius-md);
  font-weight: 500;
  margin-bottom: var(--spacing-4);
}

.total-item:last-child {
  margin-bottom: 0;
}

/* Button Group */
.button-group {
  display: flex;
  gap: var(--spacing-4);
  margin-top: var(--spacing-10);
  justify-content: flex-end;
}

.cancel-button,
.save-button {
  padding: var(--spacing-4) var(--spacing-8);
  border-radius: var(--radius-lg);
  font-weight: 600;
  cursor: pointer;
  transition: var(--transition-normal);
  display: flex;
  align-items: center;
  gap: var(--spacing-3);
}

.cancel-button {
  background: var(--neutral-50);
  border: 1px solid var(--border-color);
  color: var(--text-secondary);
}

.cancel-button:hover:not(:disabled) {
  background: var(--neutral-200);
  color: var(--text-primary);
}

.save-button {
  background: var(--primary-500);
  color: white;
  border: none;
  box-shadow: var(--shadow-sm);
}

.save-button:hover:not(:disabled) {
  background: var(--primary-600);
  transform: translateY(-2px);
  box-shadow: var(--shadow-md);
}

/* Notifications */
.success-popup {
  position: fixed;
  top: var(--spacing-8);
  right: var(--spacing-8);
  background: var(--success-500);
  color: white;
  padding: var(--spacing-4) var(--spacing-8);
  border-radius: var(--radius-lg);
  animation: slideInRight 0.3s ease-out;
  box-shadow: var(--shadow-lg);
  z-index: 2000;
  display: flex;
  align-items: center;
  gap: var(--spacing-3);
}

.error-message {
  background: var(--error-50);
  color: var(--error-600);
  padding: var(--spacing-4) var(--spacing-6);
  border-radius: var(--radius-lg);
  margin-top: var(--spacing-6);
  text-align: center;
  animation: shake 0.5s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-3);
  border: 1px solid var(--error-500);
}

/* Charity Selection Modal */
.charity-selection-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--overlay-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.2s ease-out;
  padding: var(--spacing-8);
}

.charity-selection-content {
  background-color: var(--background-white);
  border-radius: var(--radius-xl);
  padding: var(--spacing-8);
  width: 95%;
  max-width: 1600px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  animation: slideUp 0.3s ease-out;
  box-shadow: var(--shadow-lg);
}

/* Charity Selection Modal Header */
.charity-selection-content h4 {
  color: var(--text-primary);
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: var(--spacing-8);
  padding-bottom: var(--spacing-4);
  border-bottom: 2px solid var(--primary-500);
  display: flex;
  align-items: center;
  gap: var(--spacing-3);
  letter-spacing: -0.025em;
}

.charity-selection-content h4::before {
  content: '';
  display: block;
  width: 4px;
  height: 32px;
  background-color: var(--primary-500);
  border-radius: 2px;
}


/* Animations */
@keyframes fadeIn {
  from { 
    opacity: 0; 
  }
  to { 
    opacity: 1; 
  }
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes shake {
  0%, 100% { 
    transform: translateX(0); 
  }
  25% { 
    transform: translateX(-5px); 
  }
  75% { 
    transform: translateX(5px); 
  }
}

@keyframes pulse {
  0% { 
    box-shadow: 0 0 0 0 rgba(59, 130, 246, 0.4); 
  }
  70% { 
    box-shadow: 0 0 0 10px rgba(59, 130, 246, 0); 
  }
  100% { 
    box-shadow: 0 0 0 0 rgba(59, 130, 246, 0); 
  }
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

/* Loading States */
.loading-skeleton {
  background: linear-gradient(90deg, 
    var(--neutral-100) 25%, 
    var(--neutral-200) 37%, 
    var(--neutral-100) 63%
  );
  background-size: 1000px 100%;
  animation: shimmer 2s infinite linear;
  border-radius: var(--radius-md);
}

/* Empty States */
.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-10);
  text-align: center;
  color: var(--text-secondary);
  background: var(--neutral-50);
  border-radius: var(--radius-lg);
  border: 2px dashed var(--border-color);
}

.empty-state svg {
  color: var(--primary-300);
  margin-bottom: var(--spacing-4);
  width: 48px;
  height: 48px;
}

/* Tooltips */
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip:hover::before {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: var(--spacing-2) var(--spacing-3);
  background: var(--neutral-800);
  color: white;
  font-size: 0.875rem;
  border-radius: var(--radius-sm);
  white-space: nowrap;
  z-index: 1000;
  margin-bottom: var(--spacing-2);
}

.tooltip:hover::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: var(--neutral-800) transparent transparent transparent;
  margin-bottom: -3px;
}

/* Focus Styles */
:focus-visible {
  outline: none;
  box-shadow: var(--shadow-focus);
}

/* Custom Scrollbar */
.charity-selection-content {
  scrollbar-width: thin;
  scrollbar-color: var(--primary-300) var(--neutral-100);
}

.charity-selection-content::-webkit-scrollbar {
  width: 8px;
}

.charity-selection-content::-webkit-scrollbar-track {
  background: var(--neutral-100);
  border-radius: var(--radius-lg);
}

.charity-selection-content::-webkit-scrollbar-thumb {
  background: var(--primary-300);
  border-radius: var(--radius-lg);
}

.charity-selection-content::-webkit-scrollbar-thumb:hover {
  background: var(--primary-400);
}

/* Dark Mode Styles */
@media (prefers-color-scheme: dark) {
  :root {
    --background-page: var(--neutral-900);
    --background-card: var(--neutral-800);
    --background-white: var(--neutral-800);
    --text-primary: var(--neutral-50);
    --text-secondary: var(--neutral-400);
    --text-disabled: var(--neutral-600);
    --border-color: var(--neutral-700);
    
    /* Adjust shadows for dark mode */
    --shadow-sm: 0 1px 2px 0 rgb(0 0 0 / 0.3);
    --shadow-md: 0 4px 6px -1px rgb(0 0 0 / 0.3), 0 2px 4px -2px rgb(0 0 0 / 0.3);
    --shadow-lg: 0 10px 15px -3px rgb(0 0 0 / 0.3), 0 4px 6px -4px rgb(0 0 0 / 0.3);
  }

  .quantity-btn {
    background: var(--neutral-700);
  }

  .quote-upload label {
    background: var(--neutral-700);
  }

  .empty-state {
    background: var(--neutral-800);
  }

  .loading-skeleton {
    background: linear-gradient(90deg, 
      var(--neutral-800) 25%, 
      var(--neutral-700) 37%, 
      var(--neutral-800) 63%
    );
  }
}

/* Responsive Design */
@media (max-width: 1200px) {
  .assign-donation-container {
    padding: var(--spacing-6);
  }

  .charity-selection-content {
    width: 90%;
    max-width: 1200px;
  }
}

@media (max-width: 768px) {
  :root {
    --spacing-10: 2rem;
    --spacing-8: 1.5rem;
    --spacing-6: 1.25rem;
  }

  .assign-donation-container {
    padding: var(--spacing-4);
    margin: var(--spacing-4);
    width: auto;
  }

  .pallet-group-assignment {
    padding: var(--spacing-4);
  }

  .button-group {
    flex-direction: column;
  }

  .cancel-button,
  .save-button {
    width: 100%;
    justify-content: center;
  }

  .quote-upload {
    flex-direction: column;
  }

  .quote-upload label {
    width: 100%;
  }

  .total-item {
    flex-direction: column;
    gap: var(--spacing-2);
    text-align: center;
  }

  .charity-selection-overlay {
    padding: var(--spacing-4);
  }

  .charity-selection-content {
    padding: var(--spacing-4);
    width: 100%;
    max-height: 85vh;
  }
}

@media (max-width: 480px) {
  .assign-donation-container h3 {
    font-size: 1.5rem;
  }

  .pallet-quantity {
    width: 100%;
    justify-content: space-between;
  }

  .quantity-btn {
    width: 2rem;
    height: 2rem;
  }

  .success-popup {
    width: 90%;
    right: 5%;
    text-align: center;
  }
}

/* Print Styles */
@media print {
  .assign-donation-container {
    box-shadow: none;
    margin: 0;
    padding: 1cm;
  }

  .button-group,
  .quantity-btn,
  .add-group-btn,
  .remove-group-btn {
    display: none;
  }

  .pallet-group-assignment {
    break-inside: avoid;
    border: 1px solid #000;
  }

  .success-popup,
  .error-message {
    display: none;
  }
}

/* Accessibility Enhancements */
@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

.screen-reader-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

/* Charity Selection Modal Close Button */
.close-charity-selection {
  position: absolute;
  top: var(--spacing-6);
  right: var(--spacing-6);
  padding: var(--spacing-2) var(--spacing-4);
  background-color: var(--neutral-100);
  border: 1px solid var(--border-color);
  border-radius: var(--radius-lg);
  font-weight: 500;
  cursor: pointer;
  transition: var(--transition-normal);
  color: var(--text-secondary);
  display: flex;
  align-items: center;
  gap: var(--spacing-2);
  z-index: 1010;
  font-size: 0.875rem;
}

.close-charity-selection svg {
  width: 16px;
  height: 16px;
}

.close-charity-selection:hover {
  background-color: var(--neutral-200);
  color: var(--text-primary);
  transform: translateY(-1px);
  box-shadow: var(--shadow-sm);
}

.close-charity-selection:active {
  transform: translateY(0);
}

/* Dark mode support for close button */
@media (prefers-color-scheme: dark) {
  .close-charity-selection {
    background-color: var(--neutral-700);
    border-color: var(--neutral-600);
  }

  .close-charity-selection:hover {
    background-color: var(--neutral-600);
  }
}

/* Responsive styles for close button */
@media (max-width: 768px) {
  .close-charity-selection {
    top: var(--spacing-4);
    right: var(--spacing-4);
  }
}