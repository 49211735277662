.test-functions-container {
    flex: 1;
    margin-left: var(--sidebar-width);
    min-height: calc(100vh - var(--header-height));
    background-color: var(--gray-50);
    overflow-y: auto;
  }
  
  .test-functions-content {
    padding: 2rem;
    max-width: 1600px;
    margin: 0 auto;
  }
  
  .test-functions-content h1 {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 2rem;
    color: var(--gray-900);
    font-size: 1.875rem;
    font-weight: 700;
    letter-spacing: -0.025em;
  }
  
  .test-functions-content h2 {
    color: var(--gray-800);
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  
  .test-section {
    background-color: var(--white);
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    margin-bottom: 1.5rem;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  .form-group label {
    display: block;
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--gray-700);
    margin-bottom: 0.5rem;
  }
  
  .form-group input {
    width: 100%;
    padding: 0.5rem 0.75rem;
    border: 1px solid var(--gray-300);
    border-radius: 0.375rem;
    font-size: 0.875rem;
    transition: border-color 0.15s ease-in-out;
  }
  
  .form-group input:focus {
    outline: none;
    border-color: var(--blue-500);
    box-shadow: 0 0 0 1px var(--blue-500);
  }
  
  .test-button {
    width: 100%;
    padding: 0.625rem 1.25rem;
    border: none;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.15s ease-in-out;
  }
  
  .test-button-primary {
    background-color: var(--blue-600);
    color: var(--white);
  }
  
  .test-button-primary:hover {
    background-color: var(--blue-700);
  }
  
  .test-button-secondary {
    background-color: var(--green-600);
    color: var(--white);
  }
  
  .test-button-secondary:hover {
    background-color: var(--green-700);
  }
  
  .test-button:disabled {
    opacity: 0.65;
    cursor: not-allowed;
  }
  
  .unauthorized-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 2rem;
    text-align: center;
    background-color: var(--gray-50);
  }
  
  .unauthorized-message svg {
    font-size: 3rem;
    color: var(--warning-color);
    margin-bottom: 1rem;
  }
  
  .unauthorized-message h2 {
    color: var(--gray-900);
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  
  .unauthorized-message p {
    color: var(--gray-600);
    font-size: 1rem;
  }

  .test-button {
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    border: none;
    width: 100%;
    margin-top: 20px;
  }
  
  .test-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  
  .test-button-primary {
    background-color: #4a90e2;
    color: white;
  }
  
  .test-button-primary:hover:not(:disabled) {
    background-color: #357abd;
  }
  
  .test-button-secondary {
    background-color: #6c757d;
    color: white;
  }
  
  .test-button-secondary:hover:not(:disabled) {
    background-color: #545b62;
  }
  
  /* Additional styling for the container and form elements */
  .test-functions-container {
    padding: 20px;
    flex-grow: 1;
  }
  
  .test-functions-content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .test-section {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
    color: #333;
  }
  
  .form-group input {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .form-group input:focus {
    outline: none;
    border-color: #4a90e2;
    box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
  }
  
  