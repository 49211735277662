:root {
  /* Color Palette */
  --primary-color: #4f46e5;
  --primary-hover: #4338ca;
  --secondary-color: #1e293b;
  --success-color: #22c55e;
  --success-light: rgba(34, 197, 94, 0.15);
  --warning-color: #eab308;
  --warning-light: rgba(234, 179, 8, 0.15);
  --danger-color: #ef4444;
  --danger-light: rgba(239, 68, 68, 0.15);
  --info-color: #3b82f6;
  --info-light: rgba(59, 130, 246, 0.15);

  /* Neutral Colors */
  --white: #ffffff;
  --gray-50: #f8fafc;
  --gray-100: #f1f5f9;
  --gray-200: #e2e8f0;
  --gray-300: #cbd5e1;
  --gray-400: #94a3b8;
  --gray-500: #64748b;
  --gray-600: #475569;
  --gray-700: #334155;
  --gray-800: #1e293b;
  --gray-900: #0f172a;

  /* Layout */
  --sidebar-width: 280px;
  --header-height: 64px;
  --border-radius-sm: 0.375rem;
  --border-radius-md: 0.5rem;
  --border-radius-lg: 0.75rem;
  --border-radius-xl: 1rem;

  /* Shadows */
  --shadow-sm: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --shadow-md: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --shadow-lg: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  
  /* Transitions */
  --transition-all: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

/* Reset calendar date cell buttons */
.calendar-container .rbc-date-cell button {
  background: none;
  border: none;
  color: inherit;
}

/* Keep today's date cell clean */
.calendar-container .rbc-today button {
  background: none;
  color: inherit;
}

/* Layout */
.app-container {
  min-height: 100vh;
  background-color: var(--gray-50);
}

.main-content {
  display: flex;
  min-height: calc(100vh - var(--header-height));
}

.approval-requests-container {
  flex: 1;
  margin-left: var(--sidebar-width);
  min-height: calc(100vh - var(--header-height));
  background-color: var(--gray-50);
  overflow-y: auto;
}

.approval-requests-content {
  padding: 2rem;
  max-width: 1600px;
  margin: 0 auto;
}

/* Header */
h1 {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 2rem;
  color: var(--gray-900);
  font-size: 1.875rem;
  font-weight: 700;
  letter-spacing: -0.025em;
}

/* Filters Section */
.filters {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1.5rem;
  background-color: var(--white);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-md);
  margin-bottom: 2rem;
}

.filter-group {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.filter-group select,
.filter-group input[type="text"],
.filter-group input[type="date"] {
  padding: 0.625rem 1rem;
  border: 1px solid var(--gray-200);
  border-radius: var(--border-radius-md);
  font-size: 0.875rem;
  color: var(--gray-700);
  background-color: var(--white);
  transition: var(--transition-all);
  min-width: 200px;
}

.filter-group select:hover,
.filter-group input[type="text"]:hover,
.filter-group input[type="date"]:hover {
  border-color: var(--gray-400);
}

.filter-group select:focus,
.filter-group input[type="text"]:focus,
.filter-group input[type="date"]:focus {
  outline: none;
  border-color: var(--primary-color);
}

.filter-group .icon {
  color: var(--gray-500);
  font-size: 1.25rem;
}

/* Table Styles */
.approval-requests-table-container {
  background-color: var(--white);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-md);
  overflow: hidden;
  margin-bottom: 2rem;
}

.approval-requests-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.approval-requests-table th {
  background-color: var(--gray-50);
  padding: 1rem 1.5rem;
  text-align: left;
  font-weight: 600;
  color: var(--gray-700);
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  border-bottom: 1px solid var(--gray-200);
  cursor: pointer;
  transition: var(--transition-all);
}

.approval-requests-table th:hover {
  background-color: var(--gray-100);
  color: var(--primary-color);
}

.approval-requests-table td {
  padding: 1rem 1.5rem;
  color: var(--gray-700);
  border-bottom: 1px solid var(--gray-200);
}

.approval-requests-table tr:last-child td {
  border-bottom: none;
}

.approval-requests-table tr:hover {
  background-color: var(--gray-50);
}

/* Status Indicators */
.status-indicator {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.75rem;
  border-radius: var(--border-radius-full);
  font-size: 0.875rem;
  font-weight: 500;
  transition: var(--transition-all);
}

.status-pending {
  background-color: var(--warning-light);
  color: var(--warning-color);
}

.status-approved {
  background-color: var(--success-light);
  color: var(--success-color);
}

.status-rejected {
  background-color: var(--danger-light);
  color: var(--danger-color);
}

.status-in-review {
  background-color: var(--info-light);
  color: var(--info-color);
}

.status-icon {
  width: 1rem;
  height: 1rem;
  flex-shrink: 0;
}

/* Container for both buttons */
.action-buttons {
  display: flex; /* Change from inline-flex to flex */
  align-items: center;
  gap: 0.75rem;
  margin: 0; /* Remove any margin */
  justify-content: flex-start; /* Right-align the buttons */
}

/* Base button styles */
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0 1.25rem;
  font-size: 0.875rem;
  font-weight: 500;
  border-radius: var(--border-radius-md);
  transition: var(--transition-all);
  cursor: pointer;
  border: none;
  height: 2.5rem;
  width: 200px;
  line-height: 1;
  /* Ensure buttons are on the same baseline */
  vertical-align: middle;
  margin: 0; /* Reset any default margins */
}

/* Individual button styles - keep only color properties */
.btn-approve {
  background-color: var(--success-color);
  color: var(--white);
}

.btn-reject {
  background-color: var(--danger-color);
  color: var(--white);
}

.btn-view {
  background-color: var(--info-color);
  color: var(--white);
}

/* Icon alignment */
.btn svg {
  width: 1rem;
  height: 1rem;
  display: block;
}

/* Expanded Request Details */
.expanded-request-details {
  background-color: var(--white);
  border-radius: var(--border-radius-lg);
  padding: 2rem;
  margin-top: 2rem;
  box-shadow: var(--shadow-lg);
  animation: slideDown 0.3s ease-out;
}

.details-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.details-header h3 {
  color: var(--gray-900);
  font-size: 1.5rem;
  font-weight: 600;
}

.task-details-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

.task-info h3,
.bol-preview h3 {
  color: var(--gray-900);
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  padding-bottom: 0.75rem;
  border-bottom: 2px solid var(--primary-color);
}

.info-group {
  margin-bottom: 1.5rem;
  background-color: var(--gray-50);
  padding: 1.5rem;
  border-radius: var(--border-radius-lg);
  border: 1px solid var(--gray-200);
  transition: var(--transition-all);
}

.info-group:hover {
  border-color: var(--gray-300);
  box-shadow: var(--shadow-sm);
}

.info-group h4 {
  color: var(--gray-800);
  margin-bottom: 1.25rem;
  font-size: 1.125rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid var(--gray-200);
}

.info-group p {
  margin-bottom: 1rem;
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
  color: var(--gray-700);
  line-height: 1.5;
}

.info-group p:last-child {
  margin-bottom: 0;
}

.info-group strong {
  min-width: 140px;
  color: var(--gray-800);
  font-weight: 500;
  display: inline-block;
}

.info-group span {
  flex: 1;
}

.info-group svg {
  min-width: 18px;
  margin-top: 0.25rem;
}

.datetime-info {
  background-color: var(--primary-color) !important;
  border: none !important;
  padding: 2rem !important;
  margin-bottom: 1.5rem;
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-md);
}

.datetime-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem;
}

.datetime-item {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.datetime-item strong {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--white);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 1;
}

.datetime-icon {
  width: 16px;
  height: 16px;
  color: var(--white);
  flex-shrink: 0;
  position: relative;
  top: -1px; /* Fine-tune vertical alignment */
}

.datetime-content {
  color: var(--white);
  font-size: 1.75rem;
  font-weight: 600;
  letter-spacing: -0.025em;
  line-height: 1.2;
}


/* PDF Container */
.pdf-container {
  background-color: var(--white);
  border: 1px solid var(--gray-200);
  border-radius: var(--border-radius-lg);
  overflow: hidden;
  height: 610px;
}

.pdf-container iframe {
  width: 100%;
  height: 100%;
  border: none;
}

/* Calendar View */
.calendar-container {
  background-color: var(--white);
  border-radius: var(--border-radius-lg);
  padding: 1.5rem;
  box-shadow: var(--shadow-md);
  margin-bottom: 2rem;
}

.custom-event {
  padding: 0.5rem;
}

.event-title {
  font-weight: 500;
  margin-bottom: 0.25rem;
}

.event-time {
  font-size: 0.875rem;
  opacity: 0.9;
}

.event-status {
  margin-top: 0.25rem;
}

/* View Toggle */
.view-toggle {
  display: flex;
  gap: 1rem;
}

.view-toggle button {
  background-color: var(--white);
  color: var(--gray-600);
  border: 1px solid var(--gray-200);
}

.view-toggle button.active {
  background-color: var(--primary-color);
  color: var(--white);
  border-color: var(--primary-color);
}

/* Pagination */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
}

.pagination button {
  padding: 0.625rem 1.25rem;
  border: 1px solid var(--gray-200);
  background-color: var(--white);
  color: var(--gray-700);
  border-radius: var(--border-radius-md);
  transition: var(--transition-all);
  font-weight: 500;
}

.pagination button:hover:not(:disabled) {
  background-color: var(--primary-color);
  color: var(--white);
  border-color: var(--primary-color);
}

.pagination button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Animations */
@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-1rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.status-pending .status-icon {
  animation: pulse 2s infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.status-in-review .status-icon {
  animation: spin 2s linear infinite;
}

/* Responsive Design */
@media (max-width: 1280px) {
  .approval-requests-content {
    padding: 1.5rem;
  }
  
  .task-details-grid {
    grid-template-columns: 1fr;
  }

  .pdf-container {
    min-height: 500px;
  }
}

@media (max-width: 1024px) {
  .filters {
    flex-direction: column;
  }

  .filter-group {
    width: 100%;
  }

  .filter-group select,
  .filter-group input[type="text"],
  .filter-group input[type="date"] {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .approval-requests-container {
    margin-left: 0;
  }

  .approval-requests-content {
    padding: 1rem;
  }

  .approval-requests-table th,
  .approval-requests-table td {
    padding: 0.75rem 1rem;
  }

  /* Responsive table */
  .approval-requests-table thead {
    display: none;
  }

  .approval-requests-table tr {
    display: block;
    margin-bottom: 1rem;
    border: 1px solid var(--gray-200);
    border-radius: var(--border-radius-md);
  }

  .approval-requests-table tr {
    display: block;
    margin-bottom: 1rem;
    border: 1px solid var(--gray-200);
    border-radius: var(--border-radius-md);
    background-color: var(--white);
    padding: 1rem;
  }

  .approval-requests-table td {
    display: block;
    padding: 0.5rem 0;
    border: none;
    text-align: left;
    position: relative;
  }

  .approval-requests-table td::before {
    content: attr(data-label);
    font-weight: 600;
    color: var(--gray-700);
    margin-bottom: 0.25rem;
    display: block;
  }

  .approval-requests-table td:not(:last-child) {
    border-bottom: 1px solid var(--gray-200);
    padding-bottom: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .expanded-request-details {
    padding: 1rem;
  }

  /* Primary Info Section */
.primary-info {
  border-left: 4px solid var(--primary-color);
  background-color: var(--info-light);
}

.primary-info h4 {
  color: var(--primary-color);
}

/* Charity Info Section */
.charity-info {
  border-left: 4px solid var(--success-color);
  background-color: var(--success-light);
}

.charity-info h4 {
  color: var(--success-color);
}

/* Reference Info Section */
.reference-info {
  border-left: 4px solid var(--gray-500);
  background-color: var(--gray-50);
}

.reference-info h4 {
  color: var(--gray-700);
}


  .info-group {
    padding: 1rem;
  }

  .info-group p {
    flex-direction: column;
    gap: 0.25rem;
  }

  .info-group p strong {
    min-width: auto;
  }

  .view-toggle {
    flex-direction: column;
  }

  .view-toggle button {
    width: 100%;
  }

  .filters {
    padding: 1rem;
  }

  .pagination {
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.5rem;
  }

  .pagination button {
    min-width: 120px;
  }
}

/* Small screen adjustments */
@media (max-width: 480px) {
  h1 {
    font-size: 1.5rem;
  }

  .approval-requests-content {
    padding: 0.75rem;
  }

  .filter-group {
    flex-direction: column;
    align-items: stretch;
  }

  .filter-group .icon {
    display: none;
  }

  .status-indicator {
    width: 100%;
    justify-content: center;
  }

  .expanded-request-details {
    margin-top: 1rem;
  }

  .details-header {
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
  }

  .btn-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  .pdf-container {
    min-height: 300px;
  }
}

/* Accessibility Improvements */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

.btn:focus-visible,
.filter-group select:focus-visible,
.filter-group input:focus-visible {
  outline: 2px solid var(--primary-color);
  outline-offset: 2px;
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  :root {
    --white: #1a1a1a;
    --gray-50: #1f2937;
    --gray-100: #374151;
    --gray-200: #4b5563;
    --gray-300: #6b7280;
    --gray-400: #9ca3af;
    --gray-500: #d1d5db;
    --gray-600: #e5e7eb;
    --gray-700: #f3f4f6;
    --gray-800: #f9fafb;
    --gray-900: #ffffff;

    --shadow-sm: 0 1px 2px 0 rgb(0 0 0 / 0.3);
    --shadow-md: 0 4px 6px -1px rgb(0 0 0 / 0.3), 0 2px 4px -2px rgb(0 0 0 / 0.3);
    --shadow-lg: 0 10px 15px -3px rgb(0 0 0 / 0.3), 0 4px 6px -4px rgb(0 0 0 / 0.3);
  }

  .approval-requests-table tr:hover {
    background-color: rgba(255, 255, 255, 0.03);
  }

  .status-pending {
    background-color: rgba(234, 179, 8, 0.2);
  }

  .status-approved {
    background-color: rgba(34, 197, 94, 0.2);
  }

  .status-rejected {
    background-color: rgba(239, 68, 68, 0.2);
  }

  .status-in-review {
    background-color: rgba(59, 130, 246, 0.2);
  }

  .pdf-container {
    border-color: var(--gray-700);
  }
}

/* Print styles */
@media print {
  .approval-requests-container {
    margin: 0;
    padding: 0;
  }

  .filters,
  .pagination,
  .view-toggle,
  .action-buttons,
  .btn-close {
    display: none;
  }

  .approval-requests-table th,
  .approval-requests-table td {
    padding: 0.5rem;
  }

  .expanded-request-details {
    break-inside: avoid;
    box-shadow: none;
  }

  .status-indicator {
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }
}