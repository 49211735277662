:root {
    /* Core color palette - maintained but enhanced */
    --primary-color: #3498db;
    --primary-dark: #2980b9;
    --primary-light: #ebf5fb;
    --secondary-color: #2c3e50;
    --secondary-light: #34495e;
    --success-color: #28a745;
    --success-light: #d4edda;
    --danger-color: #dc3545;
    --warning-color: #ffa500;
    --info-color: #17a2b8;
    --light-gray: #f8f9fa;
    --border-color: #e1e4e8;
    --text-color: #333;
    --text-muted: #6c757d;
    --white: #ffffff;
  
    /* Layout constants */
    --sidebar-width: 250px;
    --header-height: 60px;
    
    /* New system variables */
    --shadow-sm: 0 2px 4px rgba(0, 0, 0, 0.05);
    --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.1);
    --shadow-lg: 0 10px 15px rgba(0, 0, 0, 0.1);
    --border-radius-sm: 4px;
    --border-radius-md: 8px;
    --border-radius-lg: 12px;
    --transition-base: all 0.2s ease-in-out;
    --font-weight-medium: 500;
    --font-weight-bold: 600;
  }
  
  /* Layout & Container Styles */
  .app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: var(--light-gray);
  }
  
  .main-content {
    display: flex;
    flex: 1;
  }
  
  .quotes-container {
    flex: 1;
    padding: 2rem;
    margin-left: var(--sidebar-width);
    min-height: calc(100vh - var(--header-height));
    overflow-y: auto;
  }
  
  /* Typography */
  h1, h2, h3 {
    color: var(--secondary-color);
    margin: 0;
    line-height: 1.4;
  }
  
  h1 {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 1.5rem;
    font-size: 1.75rem;
    border-bottom: 2px solid var(--primary-color);
    padding-bottom: 0.75rem;
  }
  
  /* Enhanced Filter Section */
  .filters {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
    margin-bottom: 2rem;
    background-color: var(--white);
    padding: 1.5rem;
    border-radius: var(--border-radius-md);
    box-shadow: var(--shadow-md);
    transition: var(--transition-base);
  }
  
  .filter-group {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    width: 100%;
  }
  
  .filter-group select,
  .filter-group input[type="text"],
  .filter-group input[type="date"] {
    flex: 1;
    min-width: 0; /* Prevents flex items from overflowing */
    padding: 0.625rem;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius-sm);
    font-size: 0.875rem;
    transition: var(--transition-base);
    background-color: var(--white);
  }
  
  .filter-group select:hover,
  .filter-group input[type="text"]:hover,
  .filter-group input[type="date"]:hover {
    border-color: var(--primary-color);
  }
  
  .filter-group .icon {
    color: var(--primary-color);
    font-size: 1.1rem;
  }
  
  /* Modern Table Design */
  .quotes-table-container {
    background-color: var(--white);
    border-radius: var(--border-radius-md);
    box-shadow: var(--shadow-md);
    overflow: hidden;
  }
  
  .quotes-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
  }
  
  .quotes-table th {
    background-color: var(--primary-color);
    color: var(--white);
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
    font-size: 0.875rem;
    letter-spacing: 0.05em;
    padding: 1rem 1.5rem;
    cursor: pointer;
    transition: var(--transition-base);
  }
  
  .quotes-table th:hover {
    background-color: var(--primary-dark);
  }
  
  .quotes-table td {
    padding: 1rem 1.5rem;
    border-bottom: 1px solid var(--border-color);
    color: var(--text-color);
  }
  
  .quotes-table tr:hover {
    background-color: var(--primary-light);
  }
  
  /* Enhanced Status Indicator */
  .status-indicator {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.375rem 1rem;
    border-radius: var(--border-radius-lg);
    font-size: 0.875rem;
    font-weight: var(--font-weight-medium);
    color: var(--white);
    min-width: 120px;
    justify-content: center;
    white-space: nowrap;
    transition: var(--transition-base);
    box-shadow: var(--shadow-sm);
  }
  
  .status-indicator svg {
    font-size: 1em;
    margin-right: 0.375rem;
  }
  
  /* Modern Button Styles */
  .btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 0.625rem 1.25rem;
    border: none;
    border-radius: var(--border-radius-sm);
    font-size: 0.875rem;
    font-weight: var(--font-weight-medium);
    cursor: pointer;
    transition: var(--transition-base);
    box-shadow: var(--shadow-sm);
  }
  
  .btn:hover:not(:disabled) {
    transform: translateY(-1px);
    box-shadow: var(--shadow-md);
  }
  
  .btn svg {
    font-size: 1.1em;
  }
  
  .btn-view {
    background-color: var(--info-color);
    color: var(--white);
  }
  
  .btn-sign {
    background-color: var(--primary-color);
    color: var(--white);
  }
  
  .btn-approve {
    background-color: var(--success-color);
    color: var(--white);
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
  }
  
  /* Quote Details Section */
  .quote-details-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    margin-top: 2rem;
    animation: fadeIn 0.3s ease-in-out;
  }
  
  .quote-details,
  .quote-pdf-container {
    background-color: var(--white);
    border-radius: var(--border-radius-md);
    padding: 1.75rem;
    box-shadow: var(--shadow-md);
  }
  
  .quote-details-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    padding-bottom: 1rem;
    border-bottom: 2px solid var(--primary-light);
  }
  
  .info-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1.25rem;
    padding: 0.75rem;
    border-radius: var(--border-radius-sm);
    background-color: var(--light-gray);
    transition: var(--transition-base);
  }
  
  .info-group:hover {
    background-color: var(--primary-light);
  }
  
  /* Signature Area */
  .signature-container {
    margin-top: 1.5rem;
    padding: 1.5rem;
    border-radius: var(--border-radius-md);
    background-color: var(--light-gray);
  }
  
  .signature-canvas {
    border: 2px dashed var(--border-color);
    border-radius: var(--border-radius-sm);
    width: 100%;
    height: 200px;
    margin-bottom: 1.25rem;
    background-color: var(--white);
    transition: var(--transition-base);
  }
  
  .signature-canvas:hover {
    border-color: var(--primary-color);
  }
  
  /* Pagination Improvements */
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    gap: 1rem;
  }
  
  .pagination button {
    padding: 0.625rem 1.25rem;
    border: 2px solid var(--primary-color);
    background-color: var(--white);
    color: var(--primary-color);
    border-radius: var(--border-radius-sm);
    font-weight: var(--font-weight-medium);
    transition: var(--transition-base);
  }
  
  .pagination button:hover:not(:disabled) {
    background-color: var(--primary-color);
    color: var(--white);
    transform: translateY(-1px);
  }
  
  /* Responsive Improvements */
  @media (max-width: 1024px) {
    .quotes-container {
      padding: 1.5rem;
    }
  
    .filters {
      flex-direction: column;
      gap: 1rem;
    }
  
    .filter-group {
      width: 100%;
    }
  }
  
  @media (max-width: 768px) {
    .quotes-table {
      display: block;
      overflow-x: auto;
    }
  
    .quote-details-container {
      grid-template-columns: 1fr;
    }
  
    .signature-actions {
      flex-direction: column;
      gap: 0.75rem;
    }
  
    .info-group {
      padding: 0.5rem;
    }
  }
  
  /* Animation Keyframes */
  @keyframes fadeIn {
    from { 
      opacity: 0;
      transform: translateY(10px);
    }
    to { 
      opacity: 1;
      transform: translateY(0);
    }
  }

  .quote-actions {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    margin-top: 2rem;
  }
  
  .rejection-container {
    background-color: var(--light-gray);
    padding: 1.5rem;
    border-radius: var(--border-radius-md);
    margin-top: 1rem;
  }
  
  .rejection-reason {
    width: 100%;
    min-height: 100px;
    padding: 0.75rem;
    margin: 1rem 0;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius-sm);
    resize: vertical;
    font-family: inherit;
  }
  
  .btn-reject {
    background-color: var(--danger-color);
    color: var(--white);
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    transition: var(--transition-base);
  }
  
  .btn-reject:hover:not(:disabled) {
    background-color: #c82333;
  }
  
  .btn-reject:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  .rejection-information {
    margin-top: 1.5rem;
    padding: 1.5rem;
    background-color: var(--light-gray);
    border-radius: var(--border-radius-md);
    border-left: 4px solid var(--danger-color);
  }