/* ==========================================================================
   ManageLogistics.css - Main Container and Layout
   ========================================================================== */

   :root {
    --primary-color: #3498db;
    --primary-dark: #2980b9;
    --success-color: #28a745;
    --success-dark: #219653;
    --danger-color: #e74c3c;
    --danger-dark: #c0392b;
    --warning-color: #f1c40f;
    --neutral-color: #6c757d;
    --neutral-dark: #495057;
    --background-color: #f5f7fa;
    --border-color: #e1e4e8;
    --text-color: #2c3e50;
    --white: #ffffff;
    
    --border-radius-sm: 4px;
    --border-radius-md: 8px;
    --border-radius-lg: 16px;
    
    --spacing-xs: 0.5rem;
    --spacing-sm: 0.75rem;
    --spacing-md: 1rem;
    --spacing-lg: 1.5rem;
    --spacing-xl: 2rem;
    
    --shadow-sm: 0 2px 5px rgba(0, 0, 0, 0.1);
    --shadow-md: 0 2px 10px rgba(0, 0, 0, 0.1);
    
    --transition-speed: 0.3s;
  }
  
  /* Main Container */
  .manage-logistics-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: var(--background-color);
    color: var(--text-color);
    margin-left: var(--sidebar-width);
    min-height: calc(100vh - var(--header-height));
    overflow-y: auto;
    animation: fadeIn var(--transition-speed) ease-out;
  }
  
  .logistics-content {
    padding: var(--spacing-xl);
  }
  
  /* ==========================================================================
     Typography
     ========================================================================== */
  
  h1 {
    display: flex;
    align-items: center;
    gap: var(--spacing-xs);
    margin-bottom: var(--spacing-lg);
    color: var(--text-color);
    font-size: 2rem;
  }
  
  /* ==========================================================================
     Filters Section
     ========================================================================== */
  
  .filters {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-md);
    margin-bottom: var(--spacing-xl);
    background-color: var(--white);
    padding: var(--spacing-lg);
    border-radius: var(--border-radius-md);
    box-shadow: var(--shadow-md);
  }
  
  .filter-group {
    display: flex;
    align-items: center;
    gap: var(--spacing-xs);
  }
  
  .filter-group select,
  .filter-group input[type="text"],
  .filter-group input[type="date"] {
    padding: var(--spacing-sm);
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius-sm);
    font-size: 1rem;
    transition: all var(--transition-speed) ease;
    background-color: var(--white);
  }
  
  .filter-group select:focus,
  .filter-group input[type="text"]:focus,
  .filter-group input[type="date"]:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.1);
  }
  
  .icon {
    color: var(--primary-color);
    font-size: 1.2rem;
  }
  
  /* ==========================================================================
     Tables
     ========================================================================== */
  
  .logistics-table-container {
    overflow-x: auto;
    background-color: var(--white);
    border-radius: var(--border-radius-md);
    box-shadow: var(--shadow-md);
  }
  
  .logistics-table,
  .pallet-group-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
  }
  
  .logistics-table th,
  .logistics-table td,
  .pallet-group-table th,
  .pallet-group-table td {
    padding: var(--spacing-lg);
    text-align: left;
    border-bottom: 1px solid var(--border-color);
  }
  
  .logistics-table th,
  .pallet-group-table th {
    background-color: var(--primary-color);
    color: var(--white);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.9rem;
    letter-spacing: 0.05em;
  }
  
  .logistics-table tr:hover,
  .pallet-group-table tr:hover {
    background-color: rgba(52, 152, 219, 0.05);
  }
  
  /* ==========================================================================
     Status Indicators
     ========================================================================== */
  
  .status-indicator {
    display: inline-flex;
    align-items: center;
    gap: var(--spacing-xs);
    padding: var(--spacing-xs) var(--spacing-sm);
    border-radius: var(--border-radius-lg);
    font-size: 0.875rem;
    font-weight: 500;
    text-transform: capitalize;
  }
  
  .status-indicator.notScheduled {
    background-color: var(--neutral-color);
    color: var(--white);
  }
  
  .status-indicator.pendingApproval {
    background-color: var(--warning-color);
    color: var(--neutral-dark);
  }
  
  .status-indicator.scheduled,
  .status-indicator.inProgress {
    background-color: var(--primary-color);
    color: var(--white);
  }
  
  .status-indicator.completed {
    background-color: var(--success-color);
    color: var(--white);
  }
  
  .status-indicator.rejected {
    background-color: var(--danger-color);
    color: var(--white);
  }
  
  /* ==========================================================================
     Buttons
     ========================================================================== */
  
  .btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-xs);
    padding: var(--spacing-sm) var(--spacing-lg);
    border: none;
    border-radius: var(--border-radius-sm);
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color var(--transition-speed) ease;
  }
  
  .btn:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  .btn-expand,
  .btn-schedule,
  .btn-confirm-schedule {
    background-color: var(--primary-color);
    color: var(--white);
  }
  
  .btn-expand:hover,
  .btn-schedule:hover,
  .btn-confirm-schedule:hover {
    background-color: var(--primary-dark);
  }
  
  .btn-cancel-schedule,
  .btn-cancel-complete {
    background-color: var(--danger-color);
    color: var(--white);
  }
  
  .btn-cancel-schedule:hover,
  .btn-cancel-complete:hover {
    background-color: var(--danger-dark);
  }
  
  .btn-download {
    background-color: var(--success-color);
    color: var(--white);
    text-decoration: none;
    font-size: 0.875rem;
    padding: var(--spacing-xs) var(--spacing-sm);
  }
  
  .btn-download:hover {
    background-color: var(--success-dark);
  }
  
  /* ==========================================================================
     Forms
     ========================================================================== */
  
  .scheduling-form,
  .completing-form {
    margin-top: var(--spacing-md);
    background-color: var(--background-color);
    padding: var(--spacing-lg);
    border-radius: var(--border-radius-md);
    box-shadow: var(--shadow-sm);
  }
  
  .form-group {
    margin-bottom: var(--spacing-md);
    position: relative;
  }
  
  .form-group label {
    display: block;
    margin-bottom: var(--spacing-xs);
    font-weight: 500;
    color: var(--text-color);
  }
  
  .form-group input[type="date"],
  .form-group input[type="time"],
  .form-group input[type="file"] {
    width: 100%;
    padding: var(--spacing-sm);
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius-sm);
    font-size: 1rem;
    background-color: var(--white);
  }
  
  .form-group input:disabled {
    background-color: var(--background-color);
    cursor: not-allowed;
  }
  
  /* Checkbox Styling */
  .form-group {
    margin-bottom: var(--spacing-md);
  }
  
  /* Custom Checkbox Styling */
.checkbox-label {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
}

.checkbox-label input[type="checkbox"] {
  width: 1.2rem;
  height: 1.2rem;
  margin: 0;
  margin-right: 0.75rem;
  cursor: pointer;
  accent-color: var(--primary-color);
  position: relative;
  top: -1px;
}

/* Remove any before/after elements */
.checkbox-label::before,
.checkbox-label::after,
.checkbox-label span::before,
.checkbox-label span::after {
  content: none !important;
  display: none !important;
}
  
  .form-actions {
    display: flex;
    justify-content: flex-end;
    gap: var(--spacing-md);
    margin-top: var(--spacing-lg);
  }
  
  /* ==========================================================================
     Loading States
     ========================================================================== */
  
  .loading-button {
    display: flex;
    align-items: center;
    gap: var(--spacing-xs);
    justify-content: center;
  }
  
  .spinner {
    width: 16px;
    height: 16px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: var(--white);
    animation: spin 0.8s linear infinite;
  }
  
  @keyframes spin {
    to { transform: rotate(360deg); }
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  /* ==========================================================================
     Responsive Design
     ========================================================================== */
  
  @media (max-width: 1024px) {
    .filters {
      flex-direction: column;
    }
  
    .filter-group {
      width: 100%;
    }
  }
  
  @media (max-width: 768px) {
    .logistics-content {
      padding: var(--spacing-md);
    }
  
    .logistics-table th,
    .logistics-table td,
    .pallet-group-table th,
    .pallet-group-table td {
      padding: var(--spacing-sm);
    }
  
    .btn {
      padding: var(--spacing-xs) var(--spacing-sm);
      font-size: 0.875rem;
    }
  }
  
  /* ==========================================================================
     Print Styles
     ========================================================================== */
  
  @media print {
    .manage-logistics-container {
      margin-left: 0;
    }
  
    .filters,
    .btn-expand,
    .btn-schedule,
    .btn-complete,
    .btn-download {
      display: none;
    }
  
    .logistics-table,
    .pallet-group-table {
      page-break-inside: avoid;
    }
  
    .logistics-table th,
    .pallet-group-table th {
      background-color: var(--background-color);
      color: var(--text-color);
    }
  }
  
  /* ==========================================================================
     Accessibility
     ========================================================================== */
  
  @media (prefers-reduced-motion: reduce) {
    .manage-logistics-container,
    .btn,
    .spinner {
      animation: none;
      transition: none;
    }
  }