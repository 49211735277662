@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  button,
  [type='button'],
  [type='reset'],
  [type='submit'] {
    -webkit-appearance: none;
    background-color: #3b82f6;
    background-image: initial;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

h1 {
  color: black;
  font-size: 2rem;
  font-weight: 600;
  color: #2d3748;
  margin: 0 0 1.5rem 0;
  padding: 0;
  line-height: 1.2;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

/* Icon styles within headings */
h1 svg {
  width: 1.5rem;
  height: 1.5rem;
  color: currentColor;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}