/* src/styles/NewCompanySignUp.css */

.signup-container {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, #6e8efb, #a777e3);
    padding: 20px;
  }
  
  .signup-card {
    background: white;
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 100%;
    max-width: 800px;
    display: flex;
  }
  
  .signup-image {
    flex: 1;
    background: url('https://static.wixstatic.com/media/nsplsh_72553bee075f4ad9b85ae4b5ecdc8fd1~mv2.jpg/v1/fill/w_1284,h_802,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Image%20by%20Timelab.jpg') center/cover;
    min-height: 300px;
  }
  
  .signup-form {
    flex: 1;
    padding: 40px;
  }
  
  .signup-title {
    font-size: 28px;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .form-section {
    margin-bottom: 20px;
  }
  
  .section-title {
    font-size: 18px;
    color: #6e8efb;
    margin-bottom: 15px;
    border-bottom: 2px solid #6e8efb;
    padding-bottom: 5px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
    transition: border-color 0.3s ease;
  }
  
  .form-input:focus {
    outline: none;
    border-color: #6e8efb;
  }
  
  .submit-button {
    width: 100%;
    background-color: #6e8efb;
    color: white;
    border: none;
    padding: 12px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #5a7be0;
  }
  
  @media (max-width: 768px) {
    .signup-card {
      flex-direction: column;
    }
    
    .signup-image {
      min-height: 200px;
    }
    
    .signup-form {
      padding: 20px;
    }
  }