:root {
  --primary-color: #4a90e2;
  --secondary-color: #50e3c2;
  --text-color: #333;
  --background-color: #f5f7fa;
  --card-background: #ffffff;
  --border-color: #e1e4e8;
  --success-color: #28a745;
  --warning-color: #ffc107;
  --danger-color: #dc3545;
  --shadow-color: rgba(0, 0, 0, 0.1);
  --hover-color: #f7f9fa;
  --transition-speed: 0.3s;
}

body {
  font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
  font-size: 16px;
  line-height: 1.6;
  color: var(--text-color);
  background-color: var(--background-color);
  margin: 0;
  padding: 0;
}

.plot-wrapper {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 2rem;
}

.main-section {
  background-color: var(--card-background);
  border-radius: 12px;
  box-shadow: 0 10px 30px var(--shadow-color);
  margin-bottom: 3rem;
  overflow: hidden;
  transition: transform var(--transition-speed), box-shadow var(--transition-speed);
}

.main-section:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 40px var(--shadow-color);
}

.main-section h2 {
  font-size: 2.4rem;
  color: var(--primary-color);
  padding: 1.5rem 2rem;
  margin: 0;
  background-color: var(--hover-color);
  border-bottom: 1px solid var(--border-color);
  display: flex;
  align-items: center;
  gap: 1rem;
}

.main-section h2 svg {
  font-size: 2rem;
}

.section-content {
  padding: 2rem;
}

.subsection {
  margin-bottom: 3rem;
}

.subsection h3 {
  font-size: 1.8rem;
  color: var(--secondary-color);
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.subsection h3 svg {
  font-size: 1.5rem;
}

.chart-container {
  height: 600px;
  width: 100%;
  margin-bottom: 2rem;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 5px 15px var(--shadow-color);
  transition: transform var(--transition-speed);
}

.chart-container:hover {
  transform: scale(1.02);
}

.customization-controls,
.customization-card {
  background-color: var(--hover-color);
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  box-shadow: 0 5px 15px var(--shadow-color);
}

.control-grid,
.heatmap-controls {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
}

.control-group,
.heatmap-control {
  background-color: var(--card-background);
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 3px 10px var(--shadow-color);
  transition: transform var(--transition-speed), box-shadow var(--transition-speed);
}

.control-group:hover,
.heatmap-control:hover {
  transform: translateY(-3px);
  box-shadow: 0 5px 15px var(--shadow-color);
}

.control-group h4,
.heatmap-control h4,
.customization-card h4 {
  font-size: 1.2rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.heatmap-control h5 {
  font-size: 1rem;
  color: var(--secondary-color);
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.input-group label,
.heatmap-control label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
}

.input-group input,
.heatmap-control input {
  width: 120px;
  padding: 0.75rem;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color var(--transition-speed), box-shadow var(--transition-speed);
}

.input-group input:focus,
.heatmap-control input:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(74, 144, 226, 0.2);
}

.cross-section-controls {
  margin-top: 1.5rem;
  background-color: var(--card-background);
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 3px 10px var(--shadow-color);
}

.toggle-group {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.toggle {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.toggle input {
  display: none;
}

.toggle-slider {
  position: relative;
  width: 50px;
  height: 26px;
  background-color: #ccc;
  border-radius: 26px;
  transition: var(--transition-speed);
  margin-right: 0.75rem;
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  border-radius: 50%;
  transition: var(--transition-speed);
}

.toggle input:checked + .toggle-slider {
  background-color: var(--secondary-color);
}

.toggle input:checked + .toggle-slider:before {
  transform: translateX(24px);
}

.calculator {
  background-color: var(--hover-color);
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  box-shadow: 0 5px 15px var(--shadow-color);
}

.calculator-inputs {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
  margin-bottom: 1rem;
}

.calculator-inputs label {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  color: var(--text-color);
  font-size: 1rem;
}

.calculator-inputs input {
  margin-top: 0.5rem;
  padding: 0.75rem;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color var(--transition-speed), box-shadow var(--transition-speed);
}

.calculator-inputs input:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(74, 144, 226, 0.2);
}

button {
  padding: 0.75rem 1.5rem;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color var(--transition-speed), transform var(--transition-speed), box-shadow var(--transition-speed);
}

button:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
  box-shadow: 0 2px 5px var(--shadow-color);
}

.calculator-results {
  margin-top: 1.5rem;
  padding: 1.5rem;
  background-color: var(--card-background);
  border-radius: 8px;
  border-left: 4px solid var(--secondary-color);
  box-shadow: 0 5px 15px var(--shadow-color);
}

.calculator-results h4 {
  margin-top: 0;
  color: var(--primary-color);
  font-size: 1.2rem;
}

.calculator-results p {
  margin: 0.75rem 0;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.statistics {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.stat-item {
  background-color: var(--card-background);
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 5px 15px var(--shadow-color);
  transition: transform var(--transition-speed);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 120px;
}

.stat-item:hover {
  transform: translateY(-5px);
}

.stat-label {
  font-weight: 500;
  color: var(--text-color);
  margin-bottom: 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
}

.stat-label svg {
  color: var(--primary-color);
  font-size: 1.2rem;
}

.stat-value {
  color: var(--primary-color);
  font-size: 1.5rem;
  font-weight: 700;
  margin-top: auto;
}

.donation-destruction-chart {
  width: 100%;
  height: 400px;
  padding: 1rem;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  box-shadow: 0 2px 4px var(--shadow-color);
}

.result-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
}

/* Improved responsiveness */
@media (max-width: 768px) {
  .plot-wrapper {
    padding: 1rem;
    margin: 1rem;
  }

  .control-grid,
  .calculator-inputs,
  .statistics,
  .heatmap-controls {
    grid-template-columns: 1fr;
  }

  .toggle-group {
    flex-direction: column;
    align-items: flex-start;
  }

  .chart-container {
    height: 400px;
  }

  .main-section h2 {
    font-size: 2rem;
  }

  .subsection h3 {
    font-size: 1.5rem;
  }

  .input-group input,
  .heatmap-control input {
    width: 100%;
  }
}

/* Accessibility improvements */
@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }

  .main-section:hover,
  .control-group:hover,
  .heatmap-control:hover,
  .stat-item:hover,
  .chart-container:hover,
  button:hover {
    transform: none;
    box-shadow: none;
  }
}

/* Focus styles for better keyboard navigation */
button:focus,
input:focus,
.toggle:focus-within {
  outline: 2px solid var(--primary-color);
  outline-offset: 2px;
}

/* Animation classes */
.animate-fade-in {
  animation: fadeIn 0.5s ease-in;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.animate-slide-in {
  animation: slideIn 0.5s ease-out;
}

@keyframes slideIn {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

/* High contrast mode improvements */
@media (prefers-contrast: high) {
  :root {
    --primary-color: #0000ff;
    --secondary-color: #008000;
    --text-color: #000000;
    --background-color: #ffffff;
    --card-background: #f0f0f0;
    --border-color: #000000;
    --shadow-color: rgba(0, 0, 0, 0.5);
  }

  .toggle-slider {
    background-color: #000000;
  }

  .toggle input:checked + .toggle-slider {
    background-color: var(--success-color);
  }

  .chart-container,
  .customization-controls,
  .customization-card,
  .control-group,
  .heatmap-control,
  .cross-section-controls,
  .calculator,
  .calculator-results,
  .stat-item {
    border: 2px solid var(--border-color);
  }
}

/* New styles for consistency and improved layout */
.donation-destruction-section,
.donation-liquidation-section {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.calculator,
.heatmap-subsection {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.calculator-inputs,
.calculator-results,
.heatmap-controls {
  flex: 1;
}

.heatmap-subsection .chart-container {
  flex-grow: 1;
  min-height: 400px;
}

.statistics {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
}

.stat-item {
  padding: 1.5rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 150px;
}

.stat-label {
  font-size: 1rem;
  color: var(--text-color);
  margin-bottom: 0.5rem;
}

.stat-value {
  font-size: 2rem;
  font-weight: 700;
  color: var(--primary-color);
}

/* Improved responsive design */
@media (max-width: 768px) {
  .donation-destruction-section,
  .donation-liquidation-section {
    gap: 1rem;
  }

  .calculator,
  .heatmap-subsection {
    flex-direction: column;
  }

  .stat-item {
    min-height: auto;
    padding: 1rem;
  }

  .stat-value {
    font-size: 1.5rem;
  }
}