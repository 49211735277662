/* src/components/ErrorMessage.css */

.error-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #fff3f3;
  }
  
  .error-icon {
    font-size: 48px;
    color: #e74c3c;
    margin-bottom: 20px;
  }
  
  .error-message {
    font-size: 18px;
    color: #c0392b;
    text-align: center;
    max-width: 80%;
    line-height: 1.5;
  }