/* Design System Variables
   ========================================================================== */
   :root {
    /* Core Colors */
    --primary-color: #3498db;
    --primary-dark: #2980b9;
    --primary-light: rgba(52, 152, 219, 0.05);
    --secondary-color: #2c3e50;
    --success-color: #28a745;
    --success-light: #f0fff4;
    --danger-color: #dc3545;
    --danger-light: #fff5f5;
    --warning-color: #ffa500;
    --info-color: #17a2b8;
    
    /* Neutral Colors */
    --gray-100: #f8f9fa;
    --gray-200: #e9ecef;
    --gray-300: #dee2e6;
    --gray-400: #ced4da;
    --gray-500: #adb5bd;
    --gray-600: #6c757d;
    --gray-700: #495057;
    --gray-800: #343a40;
    --text-color: #333333;
    --white: #ffffff;
    
    /* Layout */
    --sidebar-width: 250px;
    --header-height: 60px;
    --max-content-width: 1200px;
    --section-spacing: 2rem;
    --border-radius-sm: 4px;
    --border-radius-md: 8px;
    --border-radius-lg: 12px;
    
    /* Shadows */
    --shadow-sm: 0 1px 3px rgba(0, 0, 0, 0.12);
    --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.1);
    --shadow-lg: 0 10px 15px rgba(0, 0, 0, 0.1);
    --shadow-hover: 0 8px 12px rgba(0, 0, 0, 0.15);
    --shadow-input: 0 0 0 3px rgba(52, 152, 219, 0.25);
    
    /* Typography */
    --font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
    --font-size-xs: 0.75rem;
    --font-size-sm: 0.875rem;
    --font-size-md: 1rem;
    --font-size-lg: 1.125rem;
    --font-size-xl: 1.25rem;
    --font-size-2xl: 1.5rem;
    
    /* Animation */
    --transition-fast: 150ms ease;
    --transition-normal: 250ms ease;
    --transition-slow: 350ms ease;
  }
  
  /* Base Styles
     ========================================================================== */
  body {
    font-family: var(--font-family);
    color: var(--text-color);
    background-color: var(--gray-100);
    line-height: 1.6;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  /* Layout Components
     ========================================================================== */
  .app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .main-content {
    display: flex;
    flex: 1;
  }
  
  .create-new-charity-container {
    flex: 1;
    padding: 2rem;
    margin-left: var(--sidebar-width);
    min-height: calc(100vh - var(--header-height));
    overflow-y: auto;
    max-width: var(--max-width);
  }
  
  /* Typography
     ========================================================================== */
  h1, h2, h3, h4, h5, h6 {
    color: var(--secondary-color);
    margin-bottom: 1.5rem;
    font-weight: 600;
    line-height: 1.3;
    svg {
      margin-right: 0.5rem; /* Adds space after the icon */
    }
  }
  
  h1 {
    font-size: var(--font-size-2xl);
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding-bottom: 1rem;
    border-bottom: 2px solid var(--primary-color);
    margin-bottom: var(--section-spacing);
  }
  
  h2 {
    font-size: var(--font-size-xl);
  }
  
  h3 {
    font-size: var(--font-size-lg);
  }

  h4 {
    font-size: var(--font-size-xl); 
  }

  h5 {
    font-size: var(--font-size-lg); 
  }
  
  /* Form Components
     ========================================================================== */
  .create-charity-form {
    background-color: var(--white);
    padding: var(--section-spacing);
    border-radius: var(--border-radius-md);
    box-shadow: var(--shadow-md);
    margin-bottom: var(--section-spacing);
    animation: fadeIn var(--transition-normal);
  }
  
  .form-section {
    border: 1px solid var(--gray-300);
    border-radius: var(--border-radius-md);
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    background-color: var(--gray-100);
    transition: all var(--transition-normal);
  }
  
  .form-section:hover {
    box-shadow: var(--shadow-hover);
  }
  
  .form-section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  
  .form-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1.5rem;
    align-items: start;
  }
  
  .form-group {
    margin-bottom: 1.5rem;
    position: relative;
  }
  
  .form-group.full-width {
    grid-column: 1 / -1;
  }
  
  /* Form Labels and Controls
     ========================================================================== */
  .form-group label {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 0.5rem;
    color: var(--gray-700);
    font-weight: 500;
    font-size: var(--font-size-md);
  }

  .form-group label svg {
    margin-right: 0.5rem;  /* Adds space after the icon */
  }
  
  .form-group label::after {
    content: '';
    margin-left: 4px;
  }
  
  .form-group:has(input[required]) label::after,
  .form-group:has(select[required]) label::after,
  .form-group:has(textarea[required]) label::after {
    content: '*';
    color: var(--danger-color);
  }
  
  .form-control {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid var(--gray-300);
    border-radius: var(--border-radius-sm);
    font-size: var(--font-size-md);
    line-height: 1.5;
    transition: all var(--transition-fast);
    background-color: var(--white);
    color: var(--text-color);
  }
  
  .form-control:hover {
    border-color: var(--primary-color);
  }
  
  .form-control:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: var(--shadow-input);
  }
  
  .form-control.error {
    border-color: var(--danger-color);
    background-color: var(--danger-light);
  }
  
  /* Checkbox and Radio Styles
     ========================================================================== */
     .checkbox-wrapper {
      position: relative;
      display: flex;
      align-items: center;
    }
    
    .checkbox-label {
      display: flex;
      align-items: center;
      cursor: pointer;
      user-select: none;
      padding-left: 2.25rem; /* Space for checkbox + padding */
      min-height: 1.5rem; /* Ensure consistent height */
    }
    
    /* Hide the default checkbox */
    .checkbox {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    
    /* Custom checkbox */
    .checkbox-label::before {
      content: '';
      position: absolute;
      left: 0;
      width: 1.5rem; /* Slightly larger checkbox */
      height: 1.5rem;
      border: 2px solid var(--gray-400);
      border-radius: 4px;
      background-color: var(--white);
      transition: all var(--transition-fast);
    }
    
    /* Hover state */
    .checkbox-label:hover::before {
      border-color: var(--primary-color);
    }
    
    /* Checked state */
    .checkbox:checked + .checkbox-label::before {
      background-color: var(--primary-color);
      border-color: var(--primary-color);
    }
    
    /* Checkmark */
    .checkbox:checked + .checkbox-label::after {
      content: '';
      position: absolute;
      left: 0.5rem; /* Center horizontally within checkbox */
      top: 0.25rem; /* Center vertically within checkbox */
      width: 0.5rem; /* Slightly larger checkmark */
      height: 0.9rem;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
    
    /* Focus state for accessibility */
    .checkbox:focus + .checkbox-label::before {
      box-shadow: var(--shadow-input);
    }
    
    /* Disabled state */
    .checkbox:disabled + .checkbox-label {
      cursor: not-allowed;
      opacity: 0.6;
    }
    
    /* Text alignment */
    .checkbox-label span {
      margin-top: 1px; /* Fine-tune vertical alignment with checkbox */
    }
  
  /* File Upload
     ========================================================================== */
  .file-upload {
    border: 2px dashed var(--gray-300);
    padding: 2rem;
    border-radius: var(--border-radius-md);
    text-align: center;
    cursor: pointer;
    transition: all var(--transition-normal);
    background-color: var(--white);
  }
  
  .file-upload:hover {
    border-color: var(--primary-color);
    background-color: var(--primary-light);
  }
  
  .file-info {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-top: 1rem;
    padding: 0.75rem 1rem;
    background-color: var(--gray-100);
    border-radius: var(--border-radius-sm);
    font-size: var(--font-size-sm);
  }

  /* CSV Upload and Preview Styles
   ========================================================================== */
.csv-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.file-upload {
  border: 2px dashed var(--gray-300);
  padding: 2rem;
  border-radius: var(--border-radius-md);
  text-align: center;
  cursor: pointer;
  transition: all var(--transition-normal);
  background-color: var(--white);
  max-width: 600px;
  margin: 0 auto;
}

.file-upload:hover {
  border-color: var(--primary-color);
  background-color: var(--primary-light);
}

.csv-preview-container {
  background-color: var(--white);
  border-radius: var(--border-radius-md);
  box-shadow: var(--shadow-md);
  padding: 1.5rem;
  margin-top: 1rem;
  animation: fadeIn var(--transition-normal);
}

/* Update existing file-info styles */
.file-info {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  margin-top: 1rem;
  padding: 0.75rem 1rem;
  background-color: var(--gray-100);
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-sm);
}

.remove-file {
  background: none;
  border: none;
  color: var(--danger-color);
  cursor: pointer;
  padding: 0.25rem;
  border-radius: 50%;
  transition: all var(--transition-fast);
}

.remove-file:hover {
  background-color: var(--danger-light);
  transform: scale(1.1);
}
  
  /* Buttons
     ========================================================================== */
     .action-button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      padding: 0.75rem 1.5rem;
      border: none;
      border-radius: 4px; /* Reduced from var(--border-radius-sm) for more rectangular look */
      font-size: var(--font-size-md); /* Increased font size */
      font-weight: 600;
      cursor: pointer;
      transition: all var(--transition-normal);
      white-space: nowrap;
      height: 48px; /* Fixed height for consistency */
      min-width: 250px; /* Minimum width for better proportions */
      text-transform: uppercase; /* Makes text more button-like */
      letter-spacing: 0.5px; /* Better text spacing */
    }
  
  .submit-button {
    composes: button;
    background-color: var(--success-color);
    color: var(--white);
    min-width: 120px;
  }
  
  .submit-button:hover:not(:disabled) {
    filter: brightness(110%);
    transform: translateY(-1px);
  }
  
  .action-button {
    composes: button;
    background-color: var(--primary-color);
    color: var(--white);
  }
  
  .action-button:hover {
    background-color: var(--primary-dark);
    transform: translateY(-1px);
  }
  
  .remove-button {
    composes: button;
    background-color: var(--danger-color);
    color: var(--white);
  }
  
  .remove-button:hover {
    filter: brightness(110%);
  }
  
  .form-actions {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    margin-top: 2rem;
  }
  
  /* Messages and Alerts
     ========================================================================== */
  .message {
    padding: 1rem 1.25rem;
    border-radius: var(--border-radius-sm);
    margin-bottom: 1.5rem;
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;
    animation: fadeIn var(--transition-normal);
    font-size: var(--font-size-sm);
    line-height: 1.5;
  }
  
  .message-error {
    background-color: var(--danger-light);
    color: var(--danger-color);
    border: 1px solid var(--danger-color);
  }
  
  .message-success {
    background-color: var(--success-light);
    color: var(--success-color);
    border: 1px solid var(--success-color);
  }
  
  .error-text {
    color: var(--danger-color);
    font-size: var(--font-size-xs);
    margin-top: 0.375rem;
    display: flex;
    align-items: center;
    gap: 0.375rem;
  }
  
  /* Animations
     ========================================================================== */
  @keyframes fadeIn {
    from { 
      opacity: 0;
      transform: translateY(4px);
    }
    to { 
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .spinner {
    animation: spin 1s linear infinite;
  }
  
  /* Responsive Design
     ========================================================================== */
  @media (max-width: 1024px) {
    .create-charity-container {
      margin-left: 0;
      padding: 1.5rem;
    }
  
    .create-charity-form {
      padding: 1.5rem;
    }
  
    .form-section {
      padding: 1.25rem;
    }
  }
  
  @media (max-width: 768px) {
    h1 {
      font-size: var(--font-size-xl);
      margin-bottom: 1.5rem;
    }
  
    .form-grid {
      grid-template-columns: 1fr;
      gap: 1rem;
    }
  
    .form-group {
      margin-bottom: 1.25rem;
    }
  
    .form-group label {
      font-size: var(--font-size-sm);
    }
  
    .form-control {
      font-size: var(--font-size-sm);
      padding: 0.625rem;
    }
  
    .file-upload {
      padding: 1.5rem;
    }
  
    .button {
      width: 100%;
      font-size: var(--font-size-sm);
    }
  
    .form-actions {
      flex-direction: column;
    }
  }
  
  /* Print Styles
     ========================================================================== */
  @media print {
    .create-charity-container {
      margin: 0;
      padding: 0;
    }
  
    .form-section {
      break-inside: avoid;
      border: none;
      padding: 0;
      margin-bottom: 2rem;
    }
  
    .button {
      display: none;
    }
  }