/* CharityCard.css */

.charity-card {
    background: #ffffff;
    border-radius: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05), 
                0 4px 12px rgba(0, 0, 0, 0.08);
    overflow: hidden;
    transition: all 0.3s ease;
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 100%;
    border: 1px solid #f1f5f9;
  }
  
  .charity-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1), 
                0 16px 32px rgba(0, 0, 0, 0.1);
  }
  
  /* Logo Section */
  .charity-card-logo {
    height: 160px;
    padding: 24px;
    background-color: #f8fafc;
    border-bottom: 1px solid #f1f5f9;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
  }
  
  .charity-logo {
    object-fit: contain;
    transition: transform 0.3s ease;
  }
  
  .charity-card:hover .charity-logo {
    transform: scale(1.05);
  }
  
  .charity-logo-placeholder {
    width: 80%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(45deg, #f1f5f9, #e2e8f0);
    border-radius: 12px;
    font-size: 36px;
    font-weight: 600;
    color: #64748b;
    transition: all 0.3s ease;
  }
  
  .charity-card:hover .charity-logo-placeholder {
    background: linear-gradient(45deg, #e2e8f0, #f1f5f9);
    transform: scale(1.05);
  }
  
  /* Title Section */
  .charity-title-section {
    padding: 24px 20px;
    text-align: center;
    border-bottom: 1px solid #f1f5f9;
    background: #ffffff;
  }
  
  .charity-header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin-bottom: 16px;
  }
  
  .charity-name {
    font-size: 18px;
    font-weight: 600;
    color: #1e293b;
    margin: 0;
    line-height: 1.4;
  }
  
  .website-link {
    color: #64748b;
    padding: 8px;
    border-radius: 8px;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }
  
  .website-link:hover {
    color: #3b82f6;
    background: #f1f5f9;
  }
  
  .charity-causes {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: center;
    overflow: hidden; /* quick fix to handle tags and keep them on two lines. this needs to be fixed*/
  }

  
  .cause-tag {
    background: #eff6ff;
    color: #3b82f6;
    padding: 6px 12px;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
    transition: all 0.2s ease;
  }
  
  .cause-tag:hover {
    background: #dbeafe;
    transform: translateY(-1px);
  }
  
  /* Content Section */
  .charity-card-content {
    padding: 24px 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background: #ffffff;
  }
  
  .charity-description {
    color: #475569;
    font-size: 14px;
    line-height: 1.6;
    margin: 0;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 68px;
  }
  
  .charity-info-section {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
  }
  
  .info-item {
    display: flex;
    align-items: center;
    gap: 12px;
    color: #64748b;
    font-size: 14px;
    padding: 8px 12px;
    background: #f8fafc;
    border-radius: 8px;
    transition: all 0.2s ease;
  }
  
  .info-item:hover {
    background: #f1f5f9;
    transform: translateX(4px);
  }
  
  .info-icon {
    color: #94a3b8;
    flex-shrink: 0;
    width: 16px;
    height: 16px;
  }
  
  .charity-goods-section {
    padding-top: 16px;
    border-top: 1px solid #f1f5f9;
  }
  
  .section-title {
    font-size: 14px;
    font-weight: 600;
    color: #475569;
    margin-bottom: 12px;
  }
  
  .goods-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  
  .good-tag {
    background: #f1f5f9;
    color: #64748b;
    padding: 6px 12px;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
    transition: all 0.2s ease;
  }
  
  .good-tag:hover {
    background: #e2e8f0;
    transform: translateY(-1px);
  }
  
  .more-tag {
    background: #e2e8f0;
    color: #64748b;
    padding: 6px 12px;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 500;
    transition: all 0.2s ease;
  }
  
  .more-tag:hover {
    background: #cbd5e1;
    transform: translateY(-1px);
  }
  
  /* Footer Section */
  .charity-card-footer {
    padding: 16px 20px;
    background: #f8fafc;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid #f1f5f9;
  }
  
  .view-details-button {
    display: flex;
    align-items: center;
    gap: 8px;
    background: #3b82f6;
    color: white;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    transition: all 0.2s ease;
    border: none;
    cursor: pointer;
  }
  
  .view-details-button:hover {
    background: #2563eb;
    transform: translateY(-1px);
  }
  
  .chevron-icon {
    font-size: 12px;
    transition: transform 0.2s ease;
  }
  
  .view-details-button:hover .chevron-icon {
    transform: translateX(4px);
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .charity-card-logo {
      height: 140px;
      padding: 20px;
    }
  
    .charity-title-section {
      padding: 20px 16px;
    }
  
    .charity-name {
      font-size: 16px;
    }
  
    .charity-card-content {
      padding: 20px 16px;
    }
  
    .charity-description {
      font-size: 13px;
    }
  
    .view-details-button {
      width: 100%;
      justify-content: center;
    }
  }
  
  /* Accessibility */
  @media (prefers-reduced-motion: reduce) {
    .charity-card,
    .charity-card *,
    .charity-logo,
    .cause-tag,
    .good-tag,
    .info-item,
    .view-details-button,
    .chevron-icon {
      transition: none;
      transform: none;
    }
  }
  
  /* High Contrast Mode */
  @media (forced-colors: active) {
    .charity-card {
      border: 2px solid CanvasText;
    }
  
    .charity-card-logo {
      border-bottom: 2px solid CanvasText;
    }
  
    .view-details-button {
      border: 2px solid ButtonText;
    }
  
    .cause-tag,
    .good-tag,
    .more-tag {
      border: 1px solid CanvasText;
    }
  }
  
  /* Print Styles */
  @media print {
    .charity-card {
      box-shadow: none;
      border: 1px solid #000;
    }
  
    .charity-card-logo {
      background: none;
    }
  
    .website-link,
    .view-details-button {
      display: none;
    }
  }