/* CSV Preview Container */
.csv-preview {
    margin-top: 1.5rem;
    border-radius: 0.5rem;
    background-color: #ffffff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
  
  /* Header Section */
  .csv-preview-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    padding: 0.5rem 0;
  }
  
  .csv-preview-title {
    font-size: 1.125rem;
    font-weight: 600;
    color: #374151;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  /* Submit Button */
  .csv-submit-button {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    font-weight: 500;
    color: #ffffff;
    background-color: #059669;
    border-radius: 0.375rem;
    transition: background-color 0.2s;
  }
  
  .csv-submit-button:hover {
    background-color: #047857;
  }
  
  .csv-submit-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .csv-submit-button .spinner {
    animation: spin 1s linear infinite;
  }
  
  /* Table Styles */
  .csv-preview-table-container {
    overflow-x: auto;
    border-radius: 0.5rem;
    border: 1px solid #e5e7eb;
  }
  
  .csv-preview-table {
    min-width: 100%;
    border-collapse: collapse;
  }
  
  /* Table Header */
  .csv-preview-table thead {
    background-color: #f9fafb;
  }
  
  .csv-preview-table th {
    padding: 0.75rem 1rem;
    text-align: left;
    font-size: 0.75rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    color: #6b7280;
    border-bottom: 1px solid #e5e7eb;
  }
  
  /* Table Body */
  .csv-preview-table tbody tr {
    border-bottom: 1px solid #e5e7eb;
  }
  
  .csv-preview-table tbody tr:last-child {
    border-bottom: none;
  }
  
  .csv-preview-table tbody tr:nth-child(even) {
    background-color: #f9fafb;
  }
  
  .csv-preview-table td {
    padding: 0.75rem 1rem;
    color: #111827;
  }
  
  /* Cell Content */
  .cell-content {
    margin-bottom: 0.25rem;
  }
  
  .cell-primary {
    font-weight: 500;
    color: #111827;
  }
  
  .cell-secondary {
    font-size: 0.875rem;
    color: #6b7280;
  }
  
  /* Loading State */
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .csv-preview-header {
      flex-direction: column;
      gap: 1rem;
    }
    
    .csv-preview-table th,
    .csv-preview-table td {
      padding: 0.5rem;
    }
    
    .cell-secondary {
      display: none;
    }
  }
  
  /* Empty State */
  .csv-preview-empty {
    padding: 2rem;
    text-align: center;
    color: #6b7280;
  }
  
  /* Error State */
  .csv-preview-error {
    padding: 1rem;
    margin: 1rem 0;
    background-color: #fee2e2;
    border: 1px solid #ef4444;
    border-radius: 0.375rem;
    color: #b91c1c;
  }
  
  /* Success Indicator */
  .validation-success {
    display: inline-flex;
    align-items: center;
    gap: 0.25rem;
    color: #059669;
    font-size: 0.875rem;
  }
  
  /* Tooltip */
  .csv-preview-tooltip {
    position: relative;
    display: inline-block;
  }
  
  .csv-preview-tooltip:hover::after {
    content: attr(data-tooltip);
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding: 0.5rem;
    background-color: #374151;
    color: #ffffff;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    white-space: nowrap;
    z-index: 10;
  }
  
  /* Scrollbar Customization */
  .csv-preview-table-container::-webkit-scrollbar {
    height: 8px;
  }
  
  .csv-preview-table-container::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }
  
  .csv-preview-table-container::-webkit-scrollbar-thumb {
    background: #d1d5db;
    border-radius: 4px;
  }
  
  .csv-preview-table-container::-webkit-scrollbar-thumb:hover {
    background: #9ca3af;
  }