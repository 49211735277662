:root {
    --primary-color: #4a90e2;
    --secondary-color: #50e3c2;
    --background-color: #f5f7fa;
    --text-color: #333;
    --card-background: #ffffff;
    --border-color: #e1e4e8;
    --success-color: #28a745;
    --warning-color: #ffc107;
    --danger-color: #dc3545;
    --shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    --transition: all 0.3s ease;
    --sidebar-width: 250px;
    --header-height: 60px;
  }
  
  /* Global Styles */
  body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: var(--background-color);
    color: var(--text-color);
  }
  
  .app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .main-content {
    display: flex;
    flex: 1;
  }
  
  /* Option Analytics Container */
  .option-analytics-container {
    flex: 1;
    padding: 2rem;
    margin-left: var(--sidebar-width);
    min-height: calc(100vh - var(--header-height));
  }
  
  /* Typography */
  h1, h2, h3, h4 {
    margin-bottom: 1rem;
    color: var(--primary-color);
  }
  
  /* Content Placeholder */
  .content-placeholder {
    background-color: var(--card-background);
    border-radius: 8px;
    padding: 2rem;
    text-align: center;
    box-shadow: var(--shadow);
    margin-top: 2rem;
  }
  
  /* Tables (for future use) */
  .table-responsive {
    overflow-x: auto;
    background-color: var(--card-background);
    border-radius: 8px;
    box-shadow: var(--shadow);
    margin-bottom: 2rem;
  }
  
  .data-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
  }
  
  .data-table th,
  .data-table td {
    padding: 1rem;
    text-align: left;
    border-bottom: 1px solid var(--border-color);
  }
  
  .data-table th {
    background-color: var(--primary-color);
    color: var(--card-background);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.9rem;
  }
  
  .data-table tr:last-child td {
    border-bottom: none;
  }
  
  .data-table tr:hover {
    background-color: rgba(74, 144, 226, 0.05);
  }
  
  /* Forms (for future use) */
  .add-form {
    background-color: var(--card-background);
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: var(--shadow);
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
  }
  
  .add-form input,
  .add-form select {
    width: 100%;
    height: 40px;
    padding: 0.5rem 0.75rem;
    font-size: 1rem;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    transition: var(--transition);
    box-sizing: border-box;
  }
  
  .add-form input:focus,
  .add-form select:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
  }
  
  .add-form button {
    grid-column: 1 / -1;
    height: 40px;
    padding: 0 0.75rem;
    background-color: var(--primary-color);
    color: var(--card-background);
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: var(--transition);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
  
  .add-form button:hover {
    background-color: var(--secondary-color);
  }
  
  /* Buttons */
  .icon-button {
    padding: 0.5rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: var(--transition);
    margin-right: 0.5rem;
    color: var(--card-background);
  }
  
  .edit-btn { background-color: var(--warning-color); }
  .delete-btn { background-color: var(--danger-color); }
  .reset-btn { background-color: var(--primary-color); }
  .save-btn { background-color: var(--success-color); }
  .cancel-btn { background-color: var(--text-color); }
  
  .icon-button:hover {
    opacity: 0.8;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .option-analytics-container {
      padding: 1rem;
    }
  
    .add-form {
      grid-template-columns: 1fr;
    }
  
    .data-table {
      font-size: 0.9rem;
    }
  
    .data-table th,
    .data-table td {
      padding: 0.75rem 0.5rem;
    }
  
    .icon-button {
      padding: 0.4rem;
      font-size: 0.9rem;
    }
  }