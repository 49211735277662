.explore-charities-container {
    flex: 1;
    margin-left: var(--sidebar-width);
    min-height: calc(100vh - var(--header-height));
    background-color: var(--gray-50);
    overflow-y: auto;
  }
  
  .explore-charities-content {
    padding: 2rem;
    max-width: 1600px;
    margin: 0 auto;
  }
  
  .explore-charities-content h1 {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 2rem;
    color: var(--gray-900);
    font-size: 1.875rem;
    font-weight: 700;
    letter-spacing: -0.025em;
  }
  
  .unauthorized-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 2rem;
    text-align: center;
    background-color: var(--gray-50);
  }
  
  .unauthorized-message svg {
    font-size: 3rem;
    color: var(--warning-color);
    margin-bottom: 1rem;
  }
  
  .unauthorized-message h2 {
    color: var(--gray-900);
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  
  .unauthorized-message p {
    color: var(--gray-600);
    font-size: 1rem;
  }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .explore-charities-container {
      margin-left: 0;
    }
  }
  
  @media (max-width: 768px) {
    .explore-charities-content {
      padding: 1rem;
    }
  
    .explore-charities-content h1 {
      font-size: 1.5rem;
    }
  }
  
  @media (max-width: 480px) {
    .explore-charities-content {
      padding: 0.75rem;
    }
  }