/* CharityMap.css */
.charity-map-container {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 600px;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .charity-map {
    width: 100%;
    height: 100%;
  }
  
  /* Google Maps Info Window Styles */
  .map-popup {
    padding: 12px;
    max-width: 300px;
  }
  
  .map-popup h3 {
    margin: 0 0 8px 0;
    font-size: 16px;
    font-weight: 600;
    color: #1a1a1a;
  }
  
  .map-popup p {
    margin: 0 0 12px 0;
    font-size: 14px;
    color: #666;
  }
  
  .map-popup-stats {
    border-top: 1px solid #eee;
    padding-top: 8px;
  }
  
  .stat-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    font-size: 13px;
  }
  
  .stat-label {
    color: #666;
  }
  
  .stat-value {
    font-weight: 500;
    color: #1a1a1a;
  }
  
  /* Loading and Error States */
  .map-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 24px;
  }
  
  .error-icon {
    font-size: 32px;
    color: #dc3545;
    margin-bottom: 16px;
  }
  
  .map-error p {
    color: #6c757d;
    font-size: 16px;
    text-align: center;
  }