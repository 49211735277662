/* CharityExplorer.css */

/* Main container */
.charity-explorer {
    max-width: 1440px;
    margin: 0 auto;
    padding: 24px;
    position: relative;
  }
  
  /* Header styles */
  .explorer-header {
    display: flex;
    align-items: center;
    gap: 24px; /* Increased from 16px for better spacing */
    margin-bottom: 32px; /* Increased from 24px */
    padding: 16px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  /* Search bar improvements */
  .search-bar {
    flex: 1;
    position: relative;
    max-width: 500px; 
    margin-right: 60px; 
  }
  
  .search-bar input {
    width: 100%;
    padding: 14px 16px 14px 48px; /* Increased padding for better height */
    border: 2px solid #e2e8f0; /* Increased border width */
    border-radius: 10px;
    font-size: 16px;
    transition: all 0.2s ease;
    background-color: #f8fafc; /* Lighter background for better contrast */
  }
  
  .search-bar input:focus {
    outline: none;
    border-color: #3b82f6;
    background-color: #ffffff;
    box-shadow: 0 0 0 4px rgba(59, 130, 246, 0.1);
  }
  
  /* Filter button improvements */
  .filter-button {
    padding: 14px 24px; /* Increased padding */
    border: 2px solid #e2e8f0; /* Matched with search bar */
    border-radius: 10px;
    background-color: #ffffff;
    font-weight: 600;
    color: #1e293b;
    transition: all 0.2s ease;
    min-width: max-content;
    display: inline-flex; /* Changed to inline-flex */
    align-items: center;
    gap: 10px;
  }
  
  .filter-button:hover {
    background-color: #f8fafc;
    border-color: #3b82f6;
    color: #3b82f6;
  }
  
  .view-toggle {
    display: flex;
    border: 2px solid #e2e8f0;
    border-radius: 10px;
    overflow: hidden;
    background-color: #f8fafc;
    padding: 4px;
    gap: 4px;
    height: 52px; /* Match height of other elements */
    align-self: center; /* Ensure vertical centering in flex container */
  }
  
  .view-toggle button {
    padding: 10px 16px;
    border-radius: 6px;
    background-color: transparent;
    border: none;
    color: #64748b;
    font-weight: 500;
    transition: all 0.25s ease;
    position: relative;
    min-width: 100px;
    height: 100%; /* Fill height of parent */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .view-toggle button:hover:not(.active) {
    color: #1e293b;
    background-color: rgba(203, 213, 225, 0.2);
  }
  
  .view-toggle button.active {
    background-color: #ffffff;
    color: #3b82f6;
    font-weight: 600;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  /* Results header */
  .results-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    padding-bottom: 16px;
    border-bottom: 1px solid #e2e8f0;
  }
  
  .results-count {
    color: #64748b;
    font-size: 14px;
  }
  
  /* Custom dropdown */
  .custom-dropdown {
    position: relative;
    width: 200px;
  }
  
  .dropdown-trigger {
    width: 100%;
    padding: 10px 16px;
    background-color: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #1e293b;
    transition: all 0.2s ease;
  }
  
  .dropdown-trigger:hover {
    border-color: #cbd5e1;
  }
  
  .dropdown-trigger .icon {
    transition: transform 0.2s ease;
  }
  
  .dropdown-trigger .icon.open {
    transform: rotate(180deg);
  }
  
  .dropdown-content {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    z-index: 10;
    max-height: 300px;
    overflow-y: auto;
  }
  
  .dropdown-item {
    padding: 10px 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    transition: background-color 0.2s ease;
  }
  
  .dropdown-item:hover {
    background-color: #f8fafc;
  }
  
  .dropdown-item .checkbox {
    width: 16px;
    height: 16px;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3b82f6;
  }
  
  /* Custom toggle */
.custom-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;
}

.toggle-button {
  position: relative;
  width: 48px;
  height: 24px;
  border-radius: 24px;
  border: none;
  cursor: pointer;
  padding: 0;
  background-color: #e2e8f0;
  transition: background-color 0.2s ease;
}

.toggle-button::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.2s ease;
}

.toggle-button.active {
  background-color: #3b82f6;
}

.toggle-button.active::after {
  transform: translateX(24px);
}

.custom-toggle span {
  margin-right: 8px;
  font-size: 14px;
  color: #1e293b;
}
  
  /* Filter drawer */
  .filter-drawer {
    position: fixed;
    top: 0;
    right: 0;
    width: 400px;
    height: 100vh;
    background-color: #ffffff;
    box-shadow: -4px 0 6px -1px rgba(0, 0, 0, 0.1);
    z-index: 50;
    transform: translateX(100%);
    transition: transform 0.3s ease;
    padding: 24px;
  }
  
  .filter-drawer.open {
    transform: translateX(0);
  }
  
  .filter-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }
  
  .filter-header h3 {
    font-size: 20px;
    font-weight: 600;
    color: #1e293b;
  }
  
  .close-button {
    padding: 8px;
    color: #64748b;
    border-radius: 6px;
    transition: all 0.2s ease;
  }
  
  .close-button:hover {
    background-color: #f1f5f9;
    color: #1e293b;
  }
  
  .filter-content {
    height: calc(100vh - 80px);
    overflow-y: auto;
    padding-right: 16px;
  }
  
  .filter-section {
    margin-bottom: 24px;
  }
  
  .filter-section h4 {
    font-size: 16px;
    font-weight: 500;
    color: #1e293b;
    margin-bottom: 12px;
  }
  
  /* Custom slider */
  .custom-slider {
    width: 100%;
    height: 4px;
    background-color: #e2e8f0;
    border-radius: 2px;
    -webkit-appearance: none;
  }
  
  .custom-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #3b82f6;
    cursor: pointer;
    border: 2px solid #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .slider-value {
    margin-top: 8px;
    color: #64748b;
    font-size: 14px;
  }
  
  /* Active filters */
  .active-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 16px;
  }
  
  .filter-tag {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 6px 12px;
    background-color: #f1f5f9;
    border-radius: 16px;
    font-size: 14px;
    color: #1e293b;
  }
  
  .filter-tag svg {
    cursor: pointer;
    color: #64748b;
    transition: color 0.2s ease;
  }
  
  .filter-tag svg:hover {
    color: #ef4444;
  }
  
  .clear-filters {
    padding: 6px 12px;
    color: #3b82f6;
    font-size: 14px;
    font-weight: 500;
    border-radius: 16px;
    background-color: #eff6ff;
    transition: all 0.2s ease;
  }
  
  .clear-filters:hover {
    background-color: #dbeafe;
  }
  
  /* Results grid */
  .results-grid {
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
  
  .results-grid.map {
    display: block;
    height: 600px;
    border-radius: 12px;
    overflow: hidden;
  }
  
  /* States */
  .loading-state,
  .empty-state,
  .error-state {
    text-align: center;
    padding: 48px;
    color: #64748b;
  }
  
  .spinner {
    animation: spin 1s linear infinite;
    font-size: 24px;
    color: #3b82f6;
    margin-bottom: 16px;
  }
  
  .empty-icon {
    font-size: 32px;
    color: #94a3b8;
    margin-bottom: 16px;
  }
  
  .empty-state h3,
  .error-state h3 {
    color: #1e293b;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 8px;
  }
  
  .error-state button,
  .empty-state button {
    margin-top: 16px;
    padding: 8px 16px;
    background-color: #3b82f6;
    color: white;
    border-radius: 6px;
    font-weight: 500;
    transition: all 0.2s ease;
  }
  
  .error-state button:hover,
  .empty-state button:hover {
    background-color: #2563eb;
  }
  
  /* Backdrop */
  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 40;
  }
  
  /* Animations */
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .charity-explorer {
      padding: 16px;
    }
  
    .explorer-header {
      flex-wrap: wrap;
    }
  
    .search-bar {
      width: 100%;
      order: -1;
    }
  
    .filter-drawer {
      width: 100%;
    }
  
    .results-grid {
      grid-template-columns: 1fr;
    }
  }
  
  /* Scrollbar styling */
  .filter-content::-webkit-scrollbar {
    width: 8px;
  }
  
  .filter-content::-webkit-scrollbar-track {
    background: #f1f5f9;
    border-radius: 4px;
  }
  
  .filter-content::-webkit-scrollbar-thumb {
    background: #cbd5e1;
    border-radius: 4px;
  }
  
  .filter-content::-webkit-scrollbar-thumb:hover {
    background: #94a3b8;
  }