/* DonationTracker.css */

/* Custom Properties */
:root {
  /* Colors */
  --color-background: #f8fafc;
  --color-white: #ffffff;
  --color-black: #0f172a;
  --color-overlay: rgba(15, 23, 42, 0.75);
  
  /* Text Colors */
  --color-text-primary: #1e293b;
  --color-text-secondary: #64748b;
  --color-text-tertiary: #94a3b8;
  
  /* Border Colors */
  --color-border: #e2e8f0;
  --color-border-hover: #cbd5e1;
  
  /* Shadow Colors */
  --color-shadow: rgba(15, 23, 42, 0.08);
  --color-shadow-hover: rgba(15, 23, 42, 0.12);
  
  /* Status Colors */
  --status-pending: #f59e0b;
  --status-quote-sent: #6366f1;
  --status-quote-approved: #10b981;
  --status-quote-rejected: #ef4444;
  --status-completed: #10b981;
  --status-pendingapproval: #f59e0b;
  --status-scheduled: #3b82f6;
  --status-rejected: #ef4444;
  
  /* Shadows */
  --shadow-xs: 0 1px 2px var(--color-shadow);
  --shadow-sm: 0 2px 4px var(--color-shadow);
  --shadow-md: 0 4px 8px var(--color-shadow);
  --shadow-lg: 0 8px 16px var(--color-shadow);
  --shadow-xl: 0 16px 24px var(--color-shadow);
  
  /* Border Radius */
  --radius-sm: 0.375rem;
  --radius-md: 0.5rem;
  --radius-lg: 0.75rem;
  --radius-xl: 1rem;
  --radius-2xl: 1.5rem;
  
  /* Transitions */
  --transition-fast: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  --transition-normal: 250ms cubic-bezier(0.4, 0, 0.2, 1);
  --transition-slow: 350ms cubic-bezier(0.4, 0, 0.2, 1);
  
  /* Spacing */
  --spacing-xs: 0.25rem;
  --spacing-sm: 0.5rem;
  --spacing-md: 1rem;
  --spacing-lg: 1.5rem;
  --spacing-xl: 2rem;
  --spacing-2xl: 2.5rem;
  --spacing-3xl: 3rem;
}

/* Base Container */
.donation-tracker {
  padding: var(--spacing-2xl);
  background-color: var(--color-background);
  min-height: 100vh;
}

/* Header Styles */
.donation-tracker__header {
  margin-bottom: var(--spacing-2xl);
  text-align: center;
}

.donation-tracker__title {
  font-size: 2.25rem;
  font-weight: 700;
  color: var(--color-text-primary);
  margin-bottom: var(--spacing-sm);
  letter-spacing: -0.025em;
}

.donation-tracker__subtitle {
  font-size: 1.125rem;
  color: var(--color-text-secondary);
  font-weight: 400;
}

/* Timeline Layout */
.donation-tracker__timeline {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2xl);
}

/* Group and Card Spacing */
.donation-group {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
}

/* Donation Children */
.donation-children {
  margin-left: var(--spacing-2xl);
  padding-left: var(--spacing-2xl);
  border-left: 2px dashed var(--color-border);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
  animation: fadeSlideIn var(--transition-normal);
}

/* Item Logistics */
.item-logistics {
  margin-left: var(--spacing-2xl);
  padding-left: var(--spacing-2xl);
  border-left: 2px dashed var(--color-border);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
  animation: fadeSlideIn var(--transition-normal);
  margin-top: var(--spacing-lg);
}

/* Card Base Styles */
.donation-card,
.item-card,
.logistics-card {
  background-color: var(--color-white);
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-md);
  padding: var(--spacing-xl);
  transition: all var(--transition-normal);
  position: relative;
  overflow: hidden;
}

.donation-card:hover,
.item-card:hover,
.logistics-card:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-lg);
}

/* Card Headers */
.donation-card__header,
.item-card__header,
.logistics-card__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: var(--spacing-lg);
}

.donation-card__title-group,
.item-card__title-group,
.logistics-card__title-group {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
}

/* Status Icons */
.status-icon {
  font-size: 1.5rem;
  flex-shrink: 0;
  transition: transform var(--transition-normal);
}

.donation-card:hover .status-icon,
.item-card:hover .status-icon,
.logistics-card:hover .status-icon {
  transform: scale(1.1);
}

/* Status Colors */
[class*="status-icon--pending"] { color: var(--status-pending); }
[class*="status-icon--quote-sent"] { color: var(--status-quote-sent); }
[class*="status-icon--quote-approved"] { color: var(--status-quote-approved); }
[class*="status-icon--quote-rejected"] { color: var(--status-quote-rejected); }
[class*="status-icon--completed"] { color: var(--status-completed); }
[class*="status-icon--scheduled"] { color: var(--status-scheduled); }
[class*="status-icon--pendingapproval"] { color: var(--status-pendingapproval); }
[class*="status-icon--rejected"] { color: var(--status-rejected); }

/* Status Badges */
[class*="status-badge"] {
  display: inline-flex;
  align-items: center;
  padding: var(--spacing-xs) var(--spacing-md);
  border-radius: var(--radius-sm);
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--color-white);
  text-transform: capitalize;
  transition: transform var(--transition-normal);
}

[class*="status-badge--pending"] { background-color: var(--status-pending); }
[class*="status-badge--quote-sent"] { background-color: var(--status-quote-sent); }
[class*="status-badge--quote-approved"] { background-color: var(--status-quote-approved); }
[class*="status-badge--quote-rejected"] { background-color: var(--status-quote-rejected); }
[class*="status-badge--completed"] { background-color: var(--status-completed); }
[class*="status-badge--scheduled"] { background-color: var(--status-scheduled); }
[class*="status-badge--pendingapproval"] { background-color: var(--status-pendingapproval); }
[class*="status-badge--rejected"] { background-color: var(--status-rejected); }

/* Card Title Styles */
.donation-card__title,
.item-card__title,
.logistics-card__title {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--color-text-primary);
  margin-bottom: var(--spacing-xs);
  line-height: 1.4;
}

.donation-card__text,
.item-card__text,
.logistics-card__text {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

/* Card Details */
.donation-card__details,
.item-card__details,
.logistics-card__details {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-xl);
  margin-top: var(--spacing-lg);
}

.donation-card__detail,
.item-card__detail,
.logistics-card__detail {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  color: var(--color-text-secondary);
  font-size: 0.875rem;
  min-width: 200px;
}

.donation-card__detail-icon,
.item-card__detail-icon,
.logistics-card__detail-icon {
  color: var(--color-text-tertiary);
  font-size: 1rem;
}

/* Action Buttons */
.card__actions {
  margin-top: var(--spacing-xl);
  padding-top: var(--spacing-lg);
  border-top: 1px solid var(--color-border);
  display: flex;
  gap: var(--spacing-md);
  flex-wrap: wrap;
}

.card__action-button {
  display: inline-flex;
  align-items: center;
  gap: var(--spacing-sm);
  padding: var(--spacing-md) var(--spacing-lg);
  background-color: var(--color-white);
  border: 1px solid var(--color-border);
  border-radius: var(--radius-md);
  color: var(--color-text-primary);
  font-size: 0.875rem;
  font-weight: 500;
  text-decoration: none;
  transition: all var(--transition-normal);
}

.card__action-button:hover {
  background-color: var(--color-background);
  border-color: var(--color-border-hover);
  transform: translateY(-1px);
  box-shadow: var(--shadow-sm);
}

.card__action-icon {
  font-size: 1rem;
  color: var(--color-text-secondary);
  transition: transform var(--transition-normal);
}

.card__action-arrow {
  font-size: 0.875rem;
  margin-left: var(--spacing-xs);
  transition: transform var(--transition-normal);
}

.card__action-button:hover .card__action-arrow {
  transform: translateX(4px);
}

/* Modern Popup Styles */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, var(--color-overlay), rgba(15, 23, 42, 0.9));
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: var(--spacing-xl);
  animation: fadeIn var(--transition-normal);
}

.popup-content {
  background-color: var(--color-white);
  border-radius: var(--radius-2xl);
  padding: var(--spacing-2xl);
  max-width: 800px;
  width: 100%;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  animation: slideIn var(--transition-normal);
  box-shadow: 
    var(--shadow-xl),
    0 0 0 1px rgba(255, 255, 255, 0.1);
}

.popup-close {
  position: absolute;
  top: var(--spacing-lg);
  right: var(--spacing-lg);
  background: var(--color-background);
  border: none;
  color: var(--color-text-secondary);
  cursor: pointer;
  padding: var(--spacing-sm);
  border-radius: var(--radius-lg);
  transition: all var(--transition-normal);
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-close:hover {
  color: var(--color-text-primary);
  background-color: var(--color-border);
  transform: rotate(90deg);
}

.popup-header {
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-lg);
  margin-bottom: var(--spacing-2xl);
  padding-bottom: var(--spacing-xl);
  border-bottom: 1px solid var(--color-border);
  position: relative;
}

.popup-header::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg, var(--color-border), transparent);
}

.popup-header-icon {
  font-size: 2.5rem;
  color: var(--color-text-primary);
  background: var(--color-background);
  padding: var(--spacing-md);
  border-radius: var(--radius-lg);
}

.popup-header-content {
  flex-grow: 1;
}

.popup-header h2 {
  font-size: 1.75rem;
  font-weight: 700;
  color: var(--color-text-primary);
  margin-bottom: var(--spacing-sm);
  letter-spacing: -0.025em;
}

.popup-section {
  margin-bottom: var(--spacing-2xl);
  background: var(--color-white);
  border-radius: var(--radius-lg);
  padding: var(--spacing-xl);
  box-shadow: var(--shadow-sm);
}

.popup-section h3 {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--color-text-primary);
  margin-bottom: var(--spacing-lg);
}

.popup-section-icon {
  color: var(--color-text-secondary);
  background: var(--color-background);
  padding: var(--spacing-sm);
  border-radius: var(--radius-md);
}

.popup-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: var(--spacing-xl);
}

.popup-stat {
  padding: var(--spacing-lg);
  background: linear-gradient(to bottom right, var(--color-background), #f1f5f9);
  border-radius: var(--radius-lg);
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  transition: transform var(--transition-normal);
}

.popup-stat:hover {
  transform: translateY(-2px);
}

.popup-stat-icon {
  font-size: 1.5rem;
  color: var(--color-text-secondary);
  background: var(--color-white);
  padding: var(--spacing-sm);
  border-radius: var(--radius-md);
}

.popup-stat strong {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--color-text-secondary);
  margin-bottom: var(--spacing-xs);
}

.popup-stat p {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--color-text-primary);
}

.popup-items {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.popup-item {
  padding: var(--spacing-lg);
  background-color: var(--color-background);
  border-radius: var(--radius-lg);
  transition: all var(--transition-normal);
  border: 1px solid var(--color-border);
}

.popup-item:hover {
  transform: translateY(-2px);
  border-color: var(--color-border-hover);
  box-shadow: var(--shadow-sm);
}

.popup-item-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: var(--spacing-md);
  flex-wrap: wrap;
  gap: var(--spacing-sm);
}

.popup-item-header h4 {
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--color-text-primary);
}

.popup-item-details {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: var(--spacing-md);
}

.popup-item-stat {
  font-size: 0.875rem;
  color: var(--color-text-secondary);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.popup-item-stat strong {
  color: var(--color-text-primary);
  font-weight: 500;
}

/* Loading State */
.donation-tracker__loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
}

.donation-tracker__spinner {
  width: 48px;
  height: 48px;
  border: 3px solid var(--color-border);
  border-top-color: var(--status-pending);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Empty State */
.donation-tracker__empty {
  text-align: center;
  padding: var(--spacing-3xl) var(--spacing-2xl);
  background-color: var(--color-white);
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-md);
  margin: var(--spacing-2xl) auto;
  max-width: 600px;
}

.donation-tracker__empty-icon {
  font-size: 3rem;
  color: var(--color-text-tertiary);
  margin-bottom: var(--spacing-lg);
}

.donation-tracker__empty-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--color-text-primary);
  margin-bottom: var(--spacing-sm);
}

.donation-tracker__empty-text {
  color: var(--color-text-secondary);
  font-size: 1rem;
}

/* Error State */
.donation-tracker__error {
  text-align: center;
  padding: var(--spacing-3xl) var(--spacing-2xl);
  background-color: var(--color-white);
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-md);
  margin: var(--spacing-2xl) auto;
  max-width: 600px;
  border: 1px solid rgba(239, 68, 68, 0.2);
}

.donation-tracker__error-icon {
  font-size: 3rem;
  color: var(--status-rejected);
  margin-bottom: var(--spacing-lg);
}

.donation-tracker__error-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--status-rejected);
  margin-bottom: var(--spacing-sm);
}

.donation-tracker__error-message {
  color: var(--color-text-secondary);
  font-size: 1rem;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeSlideIn {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/* Responsive Design */
@media (max-width: 1024px) {
  .donation-tracker {
    padding: var(--spacing-xl);
  }

  .popup-content {
    margin: var(--spacing-md);
    padding: var(--spacing-xl);
  }

  .popup-stats {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .donation-tracker {
    padding: var(--spacing-lg);
  }

  .donation-children {
    margin-left: var(--spacing-lg);
    padding-left: var(--spacing-lg);
  }

  .item-logistics {
    margin-left: var(--spacing-lg);
    padding-left: var(--spacing-lg);
  }

  .donation-card__details,
  .item-card__details,
  .logistics-card__details {
    flex-direction: column;
    gap: var(--spacing-md);
  }

  .card__actions {
    flex-direction: column;
  }

  .card__action-button {
    width: 100%;
    justify-content: center;
  }

  .popup-item-details {
    grid-template-columns: 1fr;
  }

  .donation-tracker__title {
    font-size: 1.75rem;
  }
}

@media (max-width: 480px) {
  .donation-tracker {
    padding: var(--spacing-md);
  }

  .donation-children {
    margin-left: var(--spacing-md);
    padding-left: var(--spacing-md);
  }

  .item-logistics {
    margin-left: var(--spacing-md);
    padding-left: var(--spacing-md);
  }

  .donation-card,
  .item-card,
  .logistics-card {
    padding: var(--spacing-lg);
  }
}

/* Print Styles */
@media print {
  .donation-tracker {
    background: none;
    padding: 0;
  }

  .donation-card,
  .item-card,
  .logistics-card {
    break-inside: avoid;
    box-shadow: none;
    border: 1px solid var(--color-border);
  }

  .card__actions,
  .card__action-button {
    display: none;
  }

  .donation-children,
  .item-logistics {
    border-left-style: solid;
  }
}

/* Focus Styles */
.card__action-button:focus-visible {
  outline: 2px solid var(--color-text-primary);
  outline-offset: 2px;
}

/* Scrollbar Styles */
.popup-content {
  scrollbar-width: thin;
  scrollbar-color: var(--color-text-tertiary) var(--color-background);
}

.popup-content::-webkit-scrollbar {
  width: 6px;
}

.popup-content::-webkit-scrollbar-track {
  background: var(--color-background);
  border-radius: var(--radius-lg);
}

.popup-content::-webkit-scrollbar-thumb {
  background-color: var(--color-text-tertiary);
  border-radius: var(--radius-lg);
  border: 2px solid var(--color-background);
}