:root {
    /* Colors */
    --primary-color: #4a90e2;
    --primary-color-light: #6aa7e9;
    --secondary-color: #50e3c2;
    --secondary-color-dark: #3cc1a0;
    --background-color: #f8f9fa;
    --text-color: #333333;
    --text-color-light: #666666;
    --card-background: #ffffff;
    --border-color: #e1e4e8;
    --line-color: #d0d0d0;
    
    /* Sizes */
    --icon-size: 64px;
    --icon-size-mobile: 54px;
    --content-max-width: 800px;
    --step-padding: 2.5rem;
    --step-padding-mobile: 1.75rem;
    
    /* Effects */
    --border-radius: 16px;
    --shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    --shadow-hover: 0 15px 40px rgba(0, 0, 0, 0.15);
    --transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
  
  body {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background-color: var(--background-color);
    color: var(--text-color);
    line-height: 1.6;
    margin: 0;
    padding: 0;
  }
  
  .help-container {
    max-width: var(--content-max-width);
    margin: 4rem auto;
    padding: 0 var(--step-padding);
  }
  
  .help-container h1 {
    text-align: center;
    color: var(--primary-color);
    font-size: clamp(2.5rem, 5vw, 3.5rem);
    font-weight: 800;
    letter-spacing: -0.03em;
    margin-bottom: 4rem;
    line-height: 1.2;
  }
  
  .steps-container {
    position: relative;
    padding-left: calc(var(--icon-size) / 2 + 40px);
  }
  
  .steps-container::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: calc(var(--icon-size) / 2);
    width: 2px;
    background-color: var(--line-color);
  }
  
  .step {
    position: relative;
    margin-bottom: 3.5rem;
    padding: var(--step-padding);
    background-color: var(--card-background);
    border-radius: var(--border-radius);
    box-shadow: var(--shadow);
    transition: var(--transition);
  }
  
  .step:hover {
    transform: translateY(-5px);
    box-shadow: var(--shadow-hover);
  }
  
  .step-icon {
    position: absolute;
    left: calc(-1 * (var(--icon-size) / 2 + 40px));
    top: 50%;
    transform: translateY(-50%);
    width: var(--icon-size);
    height: var(--icon-size);
    background-color: var(--primary-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--card-background);
    font-size: calc(var(--icon-size) / 2);
    z-index: 2;
    box-shadow: 0 8px 20px rgba(74, 144, 226, 0.3);
    transition: var(--transition);
  }
  
  .step:hover .step-icon {
    background-color: var(--primary-color-light);
    transform: translateY(-50%) scale(1.05);
  }
  
  .step-content h3 {
    color: var(--primary-color);
    font-size: clamp(1.5rem, 3vw, 1.75rem);
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 1rem;
    line-height: 1.3;
  }
  
  .step-content p {
    color: var(--text-color-light);
    font-size: 1.1rem;
    line-height: 1.7;
    margin-bottom: 1.25rem;
  }
  
  .step-content ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 1.25rem;
  }
  
  .step-content li {
    position: relative;
    padding-left: 1.75rem;
    margin-bottom: 0.75rem;
    font-size: 1.05rem;
  }
  
  .step-content li::before {
    content: '•';
    color: var(--secondary-color);
    position: absolute;
    left: 0;
    top: 0;
    font-size: 1.4rem;
    line-height: 1;
  }
  
  .download-button {
    background-color: var(--secondary-color);
    color: var(--card-background);
    border: none;
    padding: 0.9rem 1.8rem;
    border-radius: calc(var(--border-radius) / 2);
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: 600;
    transition: var(--transition);
    box-shadow: 0 4px 12px rgba(80, 227, 194, 0.3);
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }
  
  .download-button:hover,
  .download-button:focus {
    background-color: var(--secondary-color-dark);
    box-shadow: 0 6px 16px rgba(80, 227, 194, 0.4);
    transform: translateY(-2px);
    outline: none;
  }
  
  @media (max-width: 768px) {
    .help-container {
      padding: 0 var(--step-padding-mobile);
      margin: 3rem auto;
    }
  
    .steps-container {
      padding-left: calc(var(--icon-size-mobile) / 2 + 20px);
    }
  
    .steps-container::before {
      left: calc(var(--icon-size-mobile) / 2);
    }
  
    .step {
      padding: var(--step-padding-mobile);
      margin-bottom: 3rem;
    }
  
    .step-icon {
      left: calc(-1 * (var(--icon-size-mobile) / 2 + 20px));
      width: var(--icon-size-mobile);
      height: var(--icon-size-mobile);
      font-size: calc(var(--icon-size-mobile) / 2);
    }
  }
  
  @media (prefers-reduced-motion: reduce) {
    *,
    *::before,
    *::after {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }