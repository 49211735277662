/* src/styles/LandingPage.css */


.landing-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
}

.landing-content {
  text-align: center;
  background: rgba(255, 255, 255, 0.9);
  padding: 3rem;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.landing-title {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 1rem;
}

.landing-description {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 2rem;
}

.landing-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.landing-button {
  padding: 0.8rem 2rem;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.landing-button.login {
  background-color: #4CAF50;
  color: white;
}

.landing-button.login:hover {
  background-color: #45a049;
}

.landing-button.signup {
  background-color: #008CBA;
  color: white;
}

.landing-button.signup:hover {
  background-color: #007B9E;
}
