/* ManageDonations.css */

.manage-donations-container {
  flex: 1;
  padding: 2rem;
  background-color: #f5f7fa;
  color: #333;
  margin-left: var(--sidebar-width);
  min-height: calc(100vh - var(--header-height));
  animation: fadeIn 0.5s ease-out;
}

h1, h2, h3 {
  margin-bottom: 1rem;
  color: #4a90e2;
}

/* Filters Section */
.filters {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 2rem;
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.filter-group {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.filter-group select,
.filter-group input[type="text"],
.filter-group .react-datepicker-wrapper {
  padding: 0.5rem;
  border: 1px solid #e1e4e8;
  border-radius: 4px;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.filter-group select:focus,
.filter-group input[type="text"]:focus {
  outline: none;
  border-color: #4a90e2;
}

/* Donations Table */
.donations-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}

.donations-table th,
.donations-table td {
  padding: 1rem;
  text-align: left;
  border-bottom: 1px solid #e1e4e8;
}

.donations-table th {
  background-color: #4a90e2;
  color: white;
  font-weight: 600;
}

.donations-table tr:last-child td {
  border-bottom: none;
}

.donations-table tr:hover {
  background-color: rgba(74, 144, 226, 0.1);
}

/* Status Indicator */
.status-indicator {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 0.5rem;
  border-radius: 16px;
  font-size: 0.875rem;
  font-weight: 500;
}

.status-indicator.po-rejected {
  color: #FF4444;
}

.status-indicator.po-sent {
  color: #48BB78;
}

/* Stat Cards */
.stat-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
}

.stat-card {
  background-color: #ffffff;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.stat-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.stat-card h3 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  color: #333;
}

.stat-card p {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
  color: #4a90e2;
}

/* Manage Items Table */
.manage-items-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}

.manage-items-table th,
.manage-items-table td {
  padding: 1rem;
  text-align: left;
  border-bottom: 1px solid #e1e4e8;
}

.manage-items-table th {
  background-color: #4a90e2;
  color: white;
  font-weight: 600;
}

.manage-items-table tr:last-child td {
  border-bottom: none;
}

.manage-items-table tr:hover {
  background-color: rgba(74, 144, 226, 0.1);
}

/* Form Elements */
.form-input,
.form-textarea,
.status-select {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #e1e4e8;
  border-radius: 4px;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.form-input:focus,
.form-textarea:focus,
.status-select:focus {
  outline: none;
  border-color: #4a90e2;
}

.form-textarea {
  min-height: 100px;
  resize: vertical;
}

.file-input {
  display: block;
  margin-bottom: 0.5rem;
}

/* Buttons */
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-primary {
  background-color: #4a90e2;
  color: white;
}

.btn-primary:hover {
  background-color: #3a7bc8;
}

.btn-secondary {
  background-color: #6c757d;
  color: white;
}

.btn-secondary:hover {
  background-color: #5a6268;
}

.btn-special-quote {
  background-color: #9F7AEA;
  color: white;
}

.btn-special-quote:hover {
  background-color: #805AD5;
}

.price-edit-container {
  display: flex;
  gap: 8px;
  align-items: center;
}

.price-actions {
  display: flex;
  gap: 4px;
}

.price-display {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px;
  cursor: pointer;
}

.custom-price-input .form-input {
  width: 120px;
}

/* Draft PO Section */
.draft-po-section {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 2rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.form-group {
  flex: 1 0 200px;
  margin-bottom: 1rem;
  padding: 0 0.5rem;
}

.submit-po-btn {
  margin-top: 1rem;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .stat-cards {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

@media (max-width: 768px) {
  .manage-donations-container {
    padding: 1rem;
  }

  .filters {
    flex-direction: column;
  }

  .filter-group {
    width: 100%;
  }

  .donations-table,
  .manage-items-table {
    font-size: 0.875rem;
  }

  .donations-table th,
  .donations-table td,
  .manage-items-table th,
  .manage-items-table td {
    padding: 0.75rem;
  }

  .stat-cards {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .stat-card {
    padding: 1rem;
  }

  .draft-po-section {
    padding: 1rem;
  }
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
  .manage-donations-container,
  .stat-card,
  .btn {
    transition: none;
    animation: none;
  }
}

/* Print styles */
@media print {
  .manage-donations-container {
    margin-left: 0;
  }

  .filters,
  .btn {
    display: none;
  }

  .donations-table,
  .manage-items-table,
  .stat-cards,
  .draft-po-section {
    page-break-inside: avoid;
  }
}

.manage-items-table .actions-cell {
  width: 220px;
  max-width: 220px;
}

.manage-items-table .actions-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.manage-items-table .btn-sm {
  padding: 2px 5px;
  font-size: 0.8rem;
}

.manage-items-table .status-select {
  width: 100%;
  padding: 2px;
  font-size: 0.8rem;
}

.manage-items-table td {
  vertical-align: top;
  padding: 10px;
}

.manage-items-table .form-input,
.manage-items-table .file-input {
  width: 100%;
  margin-bottom: 5px;
}

.manage-items-table .btn-secondary {
  margin-bottom: 5px;
}

.file-upload-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.manage-items-table td {
  vertical-align: middle;
}

.fmv-input-group {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.fmv-input-group .form-input {
  flex: 1;
}

.fmv-input-group .btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.btn-secondary:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
  opacity: 0.7;
}

.btn-secondary:disabled:hover {
  background-color: #cccccc;
}

.assignment-status {
  display: inline-block;
  padding: 2px 6px;
  border-radius: 3px;
  font-size: 0.8em;
  margin-right: 5px;
}

.fully-assigned {
  background-color: #4CAF50;
  color: white;
}

.partially-assigned {
  background-color: #FFC107;
  color: black;
}

.btn-primary:disabled {
  background-color: #a0c4e9;
  color: #ffffff;
  cursor: not-allowed;
  opacity: 0.7;
}

.btn-primary:disabled:hover {
  background-color: #a0c4e9;
}

.disabled-btn {
  background-color: #a0c4e9;
  color: #ffffff;
  cursor: not-allowed;
  opacity: 0.7;
}

.disabled-btn:hover {
  background-color: #a0c4e9;
}


.btn-rejected {
  background-color: #FF4444;
  color: white;
}

.btn-rejected:hover {
  background-color: #CC3333;
}

.btn-quoted {
  background-color: #4a90e2;
  color: white;
}

.btn-quoted:hover {
  background-color: #357abd;
}

.btn-sent {
  background-color: #48BB78;
  color: white;
}

.btn-sent:hover {
  background-color: #38A169;
}

.mt-2 {
  margin-top: 0.5rem;
}

.manage-items-table td {
  vertical-align: top;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.rejection-popup {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 500px;
  overflow: hidden;
}

.rejection-popup-header {
  background: #f8f9fa;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rejection-popup-header h3 {
  margin: 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #dc3545;
}

.rejection-icon {
  color: #dc3545;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.25rem;
  cursor: pointer;
  color: #6c757d;
  padding: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s;
}

.close-button:hover {
  color: #dc3545;
}

.rejection-popup-content {
  padding: 1.5rem;
}

.rejection-detail {
  margin-bottom: 1.25rem;
}

.rejection-detail label {
  display: block;
  font-weight: 600;
  color: #495057;
  margin-bottom: 0.5rem;
}

.rejection-detail p {
  margin: 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #212529;
  line-height: 1.5;
}

.detail-icon {
  color: #6c757d;
}

.rejection-reason {
  background: #f8f9fa;
  padding: 1rem;
  border-radius: 4px;
  border-left: 4px solid #dc3545;
}

.loading-spinner {
  text-align: center;
  padding: 2rem;
  color: #6c757d;
}

.error-message {
  color: #dc3545;
  text-align: center;
  padding: 1rem;
}

.btn-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.btn-warning:hover {
  color: #856404;
  background-color: #ffeeba;
  border-color: #ffdf7e;
}

.mt-2 {
  margin-top: 0.5rem;
}

.draft-quote-section {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin-top: 2rem;
}

.price-settings {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.price-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toggle-price-btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.8rem;
  color: #4a5568;
  background: #edf2f7;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
}

.toggle-price-btn:hover {
  background: #e2e8f0;
}

.toggle-price-btn.active {
  background: #4299e1;
  color: white;
  border-color: #3182ce;
}

.custom-price-input {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.suggestion-note {
  font-size: 0.8rem;
  color: #718096;
  font-style: italic;
}

.standard-price {
  font-size: 1.1rem;
  font-weight: 500;
  color: #2d3748;
}

.shipping-info {
  background: #f7fafc;
  border-left: 3px solid #4299e1;
}

.shipping-info label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #718096;
  font-style: italic;
}

/* Enhanced tooltip styling */
.tooltip-container {
  position: relative;
  display: inline-flex;
  align-items: center;
  margin-left: 0.5rem;
}

.info-icon {
  color: #4299e1;
  font-size: 0.9rem;
  cursor: help;
}

.tooltip-text {
  visibility: hidden;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #2d3748;
  color: white;
  text-align: center;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 0.85rem;
  white-space: nowrap;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  width: max-content;
  max-width: 250px;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #2d3748 transparent transparent transparent;
}

/* Improved summary section */
.summary-details {
  background: white;
  border-radius: 6px;
  overflow: hidden;
}

.summary-item {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e2e8f0;
}

.summary-item:last-child {
  border-bottom: none;
}

.summary-item.total {
  background: #ebf8ff;
  font-size: 1.1rem;
  font-weight: 600;
}