/* src/styles/Sidebar.css */

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 250px;
  background-color: #062C58;
  color: #ecf0f1;
  overflow-x: hidden;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.sidebar-header {
  padding: 20px;
  text-align: center;
}

.logo-container {
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-link {
  display: block;
  transition: transform 0.3s ease;
}

.logo-link:hover {
  transform: scale(1.05);
}

.logo {
  max-width: 100%;
  height: auto;
  max-height: 70px;
}

.nav-links {
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
  flex-grow: 1;
}

.nav-links li {
  padding: 10px 20px;
  transition: all 0.3s ease;
}

.nav-links li:hover {
  background-color: #0c3d6e;
}

.nav-links li.active {
  background-color: #469CFF;
}

.nav-links a {
  display: flex;
  align-items: center;
  color: #ecf0f1;
  text-decoration: none;
}

.nav-links svg {
  width: 24px;
  height: 24px;
  margin-right: 15px;
}

.link-text {
  white-space: nowrap;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.nav-links li {
  animation: fadeIn 0.5s ease-in-out;
}