/* Modern CharityTable Styles */

/* Container */
.charity-table-container {
    background: #ffffff;
    border-radius: 1rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    padding: 1.5rem;
    width: auto;
    max-width: 100%;
    margin-bottom: 2rem;
  }
  
  /* Search Section */
  .search-container {
    position: relative;
    max-width: 400px;
    margin-bottom: 2rem;
  }
  
  .search-input {
    width: 100%;
    height: 3rem;
    padding: 0.75rem 1rem 0.75rem 3rem;
    background-color: #f8fafc;
    border: 2px solid #e2e8f0;
    border-radius: 0.75rem;
    font-size: 0.9375rem;
    color: #1e293b;
    transition: all 0.2s ease;
  }
  
  .search-input:focus {
    background-color: #ffffff;
    border-color: #3b82f6;
    box-shadow: 0 0 0 4px rgba(59, 130, 246, 0.1);
    outline: none;
  }
  
  .search-input::placeholder {
    color: #94a3b8;
  }
  
  .search-icon {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: #64748b;
    width: 1.25rem;
    height: 1.25rem;
    pointer-events: none;
  }
  
  /* Table Wrapper */
  .table-wrapper {
    width: 100%;
    overflow-x: auto;
    border-radius: 0.75rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  /* Table */
  .charity-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    background-color: #ffffff;
  }
  
  /* Table Header */
  .charity-table thead th {
    background-color: #f8fafc;
    padding: 1rem 1.5rem;
    font-size: 0.875rem;
    font-weight: 600;
    color: #475569;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    border-bottom: 2px solid #e2e8f0;
    text-align: left;
    white-space: nowrap;
  }
  
  .charity-table thead th:first-child {
    border-top-left-radius: 0.75rem;
  }
  
  .charity-table thead th:last-child {
    border-top-right-radius: 0.75rem;
  }
  
  /* Table Body */
  .charity-table tbody td {
    padding: 1rem 1.5rem;
    border-bottom: 1px solid #e2e8f0;
    transition: all 0.2s ease;
  }
  
  .charity-table tbody tr:last-child td {
    border-bottom: none;
  }
  
  .charity-table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 0.75rem;
  }
  
  .charity-table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 0.75rem;
  }
  
  /* Row Styles */
  .charity-table tbody tr {
    transition: all 0.2s ease;
  }
  
  .charity-table tbody tr:hover {
    background-color: #f8fafc;
  }
  
  /* Selected Row */
  .charity-table tr.selected {
    background-color: #f0f9ff;
  }
  
  .charity-table tr.selected:hover {
    background-color: #e0f2fe;
  }
  
  /* Organization Name */
  .org-name {
    font-weight: 600;
    color: #1e293b;
    font-size: 0.9375rem;
  }
  
  /* EIN */
  .ein {
    font-family: "SF Mono", "Monaco", "Inconsolata", "Fira Mono", "Droid Sans Mono", "Source Code Pro", monospace;
    color: #64748b;
    font-size: 0.875rem;
    letter-spacing: 0.025em;
  }
  
  /* Locations Count */
  .locations-count {
    font-size: 0.875rem;
    color: #64748b;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .search-results-count {
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 0.5rem;
    font-size: 0.875rem;
    color: #64748b;
    font-weight: 500;
  }

  /* Edit Button */
  .edit-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.625rem 1.25rem;
    background-color: #3b82f6;
    color: #ffffff;
    border: none;
    border-radius: 0.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    min-width: 100px;
    gap: 0.5rem;
  }
  
  .edit-button:hover {
    background-color: #2563eb;
    transform: translateY(-1px);
    box-shadow: 0 4px 6px -1px rgba(59, 130, 246, 0.2);
  }
  
  .edit-button:active {
    transform: translateY(0);
    box-shadow: none;
  }
  
  .edit-button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.3);
  }
  
  .edit-button svg {
    width: 1rem;
    height: 1rem;
  }
  
  /* Empty State */
  .empty-state {
    text-align: center;
    padding: 3rem 1.5rem;
    color: #64748b;
    font-size: 0.9375rem;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .charity-table-container {
      padding: 1rem;
      border-radius: 0.75rem;
    }
  
    .search-container {
      margin-bottom: 1.5rem;
    }
  
    .charity-table thead th,
    .charity-table tbody td {
      padding: 0.875rem 1rem;
    }
  
    .edit-button {
      padding: 0.5rem 1rem;
      min-width: 90px;
    }
  }
  
  /* Custom Scrollbar */
  .table-wrapper::-webkit-scrollbar {
    height: 8px;
  }
  
  .table-wrapper::-webkit-scrollbar-track {
    background: #f1f5f9;
    border-radius: 4px;
  }
  
  .table-wrapper::-webkit-scrollbar-thumb {
    background: #cbd5e1;
    border-radius: 4px;
  }
  
  .table-wrapper::-webkit-scrollbar-thumb:hover {
    background: #94a3b8;
  }