/* CharityProfile.css */
:root {
    --primary-color: #2563eb;
    --primary-hover: #1d4ed8;
    --secondary-color: #64748b;
    --success-color: #22c55e;
    --background-color: #f8fafc;
    --border-color: #e2e8f0;
    --text-primary: #1e293b;
    --text-secondary: #64748b;
    --shadow-sm: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --shadow-md: 0 4px 6px -1px rgb(0 0 0 / 0.1);
    --shadow-lg: 0 10px 15px -3px rgb(0 0 0 / 0.1);
    --radius-sm: 0.375rem;
    --radius-md: 0.5rem;
    --radius-lg: 0.75rem;
  }
  
  /* Modal Overlay */
  .charity-profile-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 1.5rem;
    animation: fadeIn 0.2s ease-out;
  }
  
  .charity-profile-modal {
    background-color: white;
    border-radius: var(--radius-lg);
    width: 100%;
    max-width: 1400px;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
    box-shadow: var(--shadow-lg);
    animation: slideUp 0.3s ease-out;
  }
  
  /* Close Button */
  .close-button {
    position: absolute;
    top: 1.25rem;
    right: 1.25rem;
    background: none;
    border: none;
    color: var(--text-secondary);
    font-size: 1.5rem;
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
  }
  
  .close-button:hover {
    background-color: var(--border-color);
    color: var(--text-primary);
    transform: rotate(90deg);
  }
  
  /* Header Section */
  .profile-header {
    padding: 2rem;
    border-bottom: 1px solid var(--border-color);
    background-color: white;
  }
  
  .header-content {
    display: flex;
    gap: 2rem;
    align-items: center;
  }
  
  .charity-logo {
    max-width: 600px;
    height: 120px;
    border-radius: var(--radius-md);
    object-fit: contain;
    border: 1px solid var(--border-color);
  }
  
  .charity-logo-placeholder {
    width: 120px;
    height: 120px;
    border-radius: var(--radius-md);
    background-color: var(--background-color);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.5rem;
    color: var(--secondary-color);
  }
  
  .charity-info {
    flex: 1;
  }
  
  .charity-name {
    font-size: 2rem;
    font-weight: 700;
    color: var(--text-primary);
    margin: 0 0 0.75rem 0;
  }
  
  .charity-causes {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }
  
  .cause-tag {
    background-color: var(--background-color);
    color: var(--text-secondary);
    padding: 0.375rem 0.75rem;
    border-radius: var(--radius-sm);
    font-size: 0.875rem;
    font-weight: 500;
  }
  
  .charity-actions {
    display: flex;
    gap: 1rem;
  }
  
  .action-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border-radius: var(--radius-sm);
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
    color: var(--primary-color);
    background: none;
    border: 1px solid var(--primary-color);
    text-decoration: none;
  }
  
  .action-button:hover {
    background-color: var(--primary-color);
    color: white;
  }
  
  /* Main Content Area */
  .profile-content {
    padding: 2rem;
  }
  
  .content-grid {
    display: grid;
    grid-template-columns: minmax(0, 1fr) 400px;
    gap: 2rem;
  }
  
  .main-content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  /* Main Content Column */
  .content-section {
    background-color: white;
    border-radius: var(--radius-lg);
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    border: 1px solid var(--border-color);
  }
  
  .section-title {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    font-size: 1.25rem;
    color: var(--text-primary);
    margin: 0 0 1.25rem 0;
  }
  
  .section-icon {
    color: var(--primary-color);
  }
  
  .description {
    color: var(--text-secondary);
    line-height: 1.6;
    margin: 0;
  }
  
  .notes-container {
    margin-top: 1.5rem;
    padding: 1rem;
    background-color: var(--background-color);
    border-radius: var(--radius-md);
    display: flex;
    gap: 1rem;
  }
  
  .notes-icon {
    color: var(--primary-color);
    font-size: 1.25rem;
    flex-shrink: 0;
    margin-top: 0.25rem;
  }
  
  .notes-content h3 {
    font-size: 1rem;
    margin: 0 0 0.5rem 0;
    color: var(--text-primary);
  }
  
  .notes-content p {
    margin: 0;
    color: var(--text-secondary);
    font-size: 0.875rem;
    line-height: 1.6;
  }
  
  .goods-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
  }
  
  .good-tag {
    background-color: var(--background-color);
    color: var(--text-secondary);
    padding: 0.5rem 1rem;
    border-radius: var(--radius-sm);
    font-size: 0.875rem;
    font-weight: 500;
    transition: all 0.2s;
  }
  
  .good-tag:hover {
    background-color: var(--primary-color);
    color: white;
    transform: translateY(-1px);
  }
  
  /* Location Sidebar */
  .location-sidebar {
    background-color: white;
    border-radius: var(--radius-lg);
    border: 1px solid var(--border-color);
    overflow: hidden;
  }
  
  .location-selector {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;
    background-color: var(--background-color);
  }
  
  .location-tab {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.75rem 1rem;
    background: white;
    border: 1px solid var(--border-color);
    border-radius: var(--radius-md);
    cursor: pointer;
    transition: all 0.2s;
    font-weight: 500;
    color: var(--text-secondary);
  }
  
  .location-tab:hover {
    border-color: var(--primary-color);
    color: var(--primary-color);
  }
  
  .location-tab.active {
    background-color: var(--primary-color);
    color: white;
    border-color: var(--primary-color);
  }
  
  .location-details {
    padding: 1.5rem;
  }
  
  .detail-group {
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid var(--border-color);
  }
  
  .detail-group:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
  }
  
  .detail-header {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 1rem;
  }
  
  .detail-header h3 {
    margin: 0;
    font-size: 1rem;
    color: var(--text-primary);
  }
  
  .detail-icon {
    color: var(--primary-color);
  }
  
  .address-text {
    color: var(--text-secondary);
    line-height: 1.6;
    margin: 0 0 1rem 0;
  }
  
  .address-actions {
    display: flex;
    gap: 1rem;
  }
  
  .address-actions button,
  .address-actions a {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border-radius: var(--radius-sm);
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
    text-decoration: none;
  }
  
  .address-actions button {
    background: none;
    border: 1px solid var(--border-color);
    color: var(--text-secondary);
  }
  
  .address-actions a {
    background-color: var(--primary-color);
    color: white;
    border: none;
  }
  
  .address-actions button:hover {
    border-color: var(--primary-color);
    color: var(--primary-color);
  }
  
  .address-actions a:hover {
    background-color: var(--primary-hover);
  }
  
  /* Contact Section */
  .contact-header {
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;
    margin-bottom: 1rem;
  }
  
  .contact-icon {
    color: var(--text-secondary);
    font-size: 1.25rem;
  }
  
  .contact-icon.main {
    color: var(--success-color);
  }
  
  .contact-header h3 {
    margin: 0;
    font-size: 1rem;
    color: var(--text-primary);
  }
  
  .contact-role {
    margin: 0.25rem 0 0.5rem 0;
    font-size: 0.875rem;
    color: var(--text-secondary);
  }
  
  .main-contact-badge {
    display: inline-block;
    padding: 0.25rem 0.5rem;
    background-color: var(--success-color);
    color: white;
    border-radius: var(--radius-sm);
    font-size: 0.75rem;
    font-weight: 500;
  }
  
  .contact-details {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .contact-link {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--text-secondary);
    text-decoration: none;
    font-size: 0.875rem;
    transition: color 0.2s;
  }
  
  .contact-link:hover {
    color: var(--primary-color);
  }
  
  /* Facility Section */
  .facility-capacity {
    color: var(--text-secondary);
    margin: 0 0 1rem 0;
    font-size: 0.875rem;
  }
  
  .facility-features {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
  
  .facility-feature {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    color: var(--text-secondary);
    font-size: 0.875rem;
  }
  
  /* Hours Section */
  .timezone {
    color: var(--text-secondary);
    margin: 0 0 1rem 0;
    font-size: 0.875rem;
  }
  
  .hours-grid {
    display: grid;
    gap: 0.5rem;
  }
  
  .hours-row {
    display: grid;
    grid-template-columns: 100px 1fr;
    gap: 1rem;
    padding: 0.5rem;
    border-radius: var(--radius-sm);
    transition: background-color 0.2s;
  }
  
  .hours-row:hover {
    background-color: var(--background-color);
  }
  
  .day {
    font-weight: 500;
    color: var(--text-primary);
  }
  
  .hours {
    color: var(--text-secondary);
  }
  
  /* Animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes slideUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .content-grid {
      grid-template-columns: 1fr;
    }
    
    .charity-profile-modal {
      margin: 0;
      max-height: 100vh;
      border-radius: 0;
    }
    
    .charity-profile-overlay {
      padding: 0;
    }
  }
  
  @media (max-width: 768px) {
    .header-content {
      flex-direction: column;
      text-align: center;
    }
    
    .charity-actions {
      justify-content: center;
    }
    
    .charity-causes {
      justify-content: center;
    }
    
    .profile-header {
      padding: 1.5rem;
    }
    
    .profile-content {
      padding: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    .charity-name {
      font-size: 1.5rem;
    }
    
    .address-actions {
      flex-direction: column;
    }
    
    .address-actions button,
    .address-actions a {
      width: 100%;
      justify-content: center;
    }
    
    .location-selector {
      padding: 0.75rem;
    }
    
    .location-tab {
      padding: 0.5rem 0.75rem;
      font-size: 0.875rem;
    }
    
    .location-details {
      padding: 1rem;
    }
    
    .detail-group {
      padding-bottom: 1rem;
      margin-bottom: 1rem;
    }
    
    .content-section {
      padding: 1rem;
    }
    
    .section-title {
      font-size: 1.125rem;
    }
    
    .good-tag {
      width: 100%;
      text-align: center;
    }
  }
  
  /* Print Styles */
  @media print {
    .charity-profile-overlay {
      position: static;
      padding: 0;
      background: none;
    }
    
    .charity-profile-modal {
      box-shadow: none;
      max-height: none;
    }
    
    .close-button,
    .action-button,
    .address-actions {
      display: none;
    }
    
    .content-grid {
      grid-template-columns: 1fr;
    }
    
    .location-selector {
      display: none;
    }
    
    .location-details {
      page-break-inside: avoid;
    }
    
    .detail-group {
      page-break-inside: avoid;
    }
    
    .content-section {
      page-break-inside: avoid;
      border: none;
    }
  }
  
  /* Focus States and Accessibility */
  .action-button:focus,
  .close-button:focus,
  .location-tab:focus {
    outline: 2px solid var(--primary-color);
    outline-offset: 2px;
  }
  
  .action-button:focus:not(:focus-visible),
  .close-button:focus:not(:focus-visible),
  .location-tab:focus:not(:focus-visible) {
    outline: none;
  }
  
  /* Custom Scrollbar */
  .charity-profile-modal::-webkit-scrollbar {
    width: 8px;
  }
  
  .charity-profile-modal::-webkit-scrollbar-track {
    background: var(--background-color);
  }
  
  .charity-profile-modal::-webkit-scrollbar-thumb {
    background-color: var(--secondary-color);
    border-radius: 4px;
  }
  
  .charity-profile-modal::-webkit-scrollbar-thumb:hover {
    background-color: var(--text-secondary);
  }
  
  /* Loading States */
  .charity-profile-modal.loading {
    opacity: 0.7;
    pointer-events: none;
  }
  
  /* Hover Effects */
  .content-section:hover {
    border-color: var(--primary-color);
    transform: translateY(-1px);
    transition: all 0.2s ease;
  }
  
  .detail-group:hover .detail-icon {
    transform: scale(1.1);
    transition: transform 0.2s ease;
  }
  
  .contact-header:hover .contact-icon {
    transform: rotate(5deg);
    transition: transform 0.2s ease;
  }
  
  /* Dark Mode Support */
  @media (prefers-color-scheme: dark) {
    :root {
      --background-color: #1e293b;
      --border-color: #334155;
      --text-primary: #f8fafc;
      --text-secondary: #94a3b8;
      --primary-color: #3b82f6;
      --primary-hover: #2563eb;
    }
    
    .charity-profile-modal,
    .content-section,
    .location-sidebar,
    .location-tab,
    .profile-header {
      background-color: #0f172a;
    }
    
    .charity-logo-placeholder,
    .notes-container,
    .location-selector {
      background-color: var(--background-color);
    }
    
    .good-tag:hover {
      background-color: var(--primary-color);
    }
    
    .hours-row:hover {
      background-color: var(--background-color);
    }
  }