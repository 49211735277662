/* Modern Impact Dashboard CSS - Complete Version */

:root {
    --primary-color: #3498db;
    --secondary-color: #2c3e50;
    --background-color: #f5f7fa;
    --card-background: #ffffff;
    --border-color: #e1e4e8;
    --text-color: #333;
    --text-light: #7f8c8d;
    --shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
    --transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    --container-max-width: max-width;
    --header-height: 64px;
    --sidebar-width: 250px;
    
    /* Enhanced color palette */
    --green-color: #2ecc71;
    --purple-color: #9b59b6;
    --emerald-color: #1abc9c;
    --blue-light: rgba(52, 152, 219, 0.1);
    --green-light: rgba(46, 204, 113, 0.1);
    --purple-light: rgba(155, 89, 182, 0.1);
    --emerald-light: rgba(26, 188, 156, 0.1);
    --hover-overlay: rgba(52, 152, 219, 0.05);
  }
  
  /* Main Layout */
.impact-dashboard-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    color: var(--text-color);
    margin-left: var(--sidebar-width);
    min-height: calc(100vh - var(--header-height));
    overflow-y: auto;
    animation: fadeIn 0.5s ease-out;
    padding: 2rem;
}

.impact-dashboard-content {
    max-width: var(--container-max-width);
    margin: 0 auto;
    width: 100%;
    padding: 0.5rem;
}
  
  /* Header Styles */
  .impact-header {
    margin-bottom: 2rem;
    animation: slideDown 0.6s ease-out;
  }
  
  .impact-header h1 {
    font-size: 2.25rem;
    font-weight: 700;
    color: var(--secondary-color);
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 0.5rem;
  }
  
  .impact-header p {
    color: var(--text-light);
    font-size: 1.1rem;
    line-height: 1.6;
    max-width: 600px;
  }
  
  /* Dashboard Sections Layout */
.dashboard-sections {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 1.5rem;
    margin-bottom: 2rem;
}

.timeline-section {
    background-color: var(--card-background);
    border-radius: 16px;
    padding: 1.75rem;
    box-shadow: var(--shadow);
    transition: var(--transition);
    min-height: 480px;
}

.timeline-container {
    height: 100%;
    width: 100%;
}

.charities-section {
    background-color: var(--card-background);
    border-radius: 16px;
    box-shadow: var(--shadow);
    transition: var(--transition);
    min-height: 480px;
}

  /* Enhanced Stat Cards */
  .impact-stats {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem;
    margin-bottom: 2rem;
    animation: slideUp 0.6s ease-out;
  }
  
  .impact-stat-card {
    background-color: var(--card-background);
    border-radius: 16px;
    padding: 1.75rem;
    box-shadow: var(--shadow);
    transition: var(--transition);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
    overflow: hidden;
  }
  
  .impact-stat-card::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: linear-gradient(to right, var(--primary-color), var(--purple-color));
    opacity: 0;
    transition: var(--transition);
  }
  
  .impact-stat-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
  }
  
  .impact-stat-card:hover::after {
    opacity: 1;
  }
  
  .stat-icon {
    width: 48px;
    height: 48px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    transition: var(--transition);
  }
  
  .stat-icon.blue { 
    background-color: var(--blue-light); 
    color: var(--primary-color); 
  }
  
  .stat-icon.green { 
    background-color: var(--green-light); 
    color: var(--green-color); 
  }
  
  .stat-icon.purple { 
    background-color: var(--purple-light); 
    color: var(--purple-color); 
  }
  
  .stat-icon.emerald { 
    background-color: var(--emerald-light); 
    color: var(--emerald-color); 
  }
  
  .impact-stat-card:hover .stat-icon {
    transform: scale(1.1);
  }
  
  .stat-content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .stat-content p {
    font-size: 3.5rem;
    font-weight: 700;
    color: var(--secondary-color);
    margin: 0;
    line-height: 1.2;
    transition: var(--transition);
  }
  
  .stat-content h3 {
    font-size: 0.95rem;
    color: var(--text-light);
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }
  
  /* Data Grid Layout */
  .impact-data-grid {
    display: grid;
    grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
    gap: 1.5rem;
    margin-bottom: 2rem;
    min-height: 480px;
    animation: fadeIn 0.8s ease-out;
  }
  
  /* Modernized Charity Section */
  .impact-charities-container {
    background-color: var(--card-background);
    border-radius: 16px;
    padding: 1.75rem;
    box-shadow: var(--shadow);
    transition: var(--transition);
    display: flex;
    flex-direction: column;
  }
  
  .impact-charities-container:hover {
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
  }
  
  .charities-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  
  .charities-header h3 {
    font-size: 1.25rem;
    color: var(--secondary-color);
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin: 0;
  }
  
  .charity-cards {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    overflow-y: auto;
    padding: 0.5rem;
    flex: 1;
  }
  
  .charity-impact-card {
    background-color: var(--card-background);
    border-radius: 16px;
    padding: 1.75rem;
    box-shadow: var(--shadow);
    transition: var(--transition);
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    animation: slideIn 0.5s ease-out;
  }
  
  .charity-impact-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
  }
  
  .charity-logo-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  
  .charity-logo {
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: var(--transition);
    background-color: #b8b8b8;
    object-fit: contain;
  }
  
  .charity-impact-card:hover .charity-logo {
    transform: scale(1.05);
  }
  
  .charity-rank {
    font-size: 0.875rem;
    color: var(--primary-color);
    background-color: var(--blue-light);
    padding: 0.5rem 1rem;
    border-radius: 20px;
    font-weight: 600;
    transition: var(--transition);
  }
  
  .charity-impact-card:hover .charity-rank {
    background-color: var(--primary-color);
    color: white;
  }
  
  .charity-title {
    margin-top: 1rem;
  }
  
  .charity-title h4 {
    font-size: 1.25rem;
    color: var(--secondary-color);
    margin: 0;
    font-weight: 700;
  }
  
  .charity-mission {
    background-color: var(--background-color);
    border-radius: 12px;
    padding: 1.25rem;
    margin: 1rem 0;
    position: relative;
    transition: var(--transition);
  }
  
  .charity-mission:hover {
    background-color: var(--blue-light);
  }
  
  .charity-mission svg {
    position: absolute;
    top: 1rem;
    left: 1rem;
    width: 16px;
    height: 16px;
    color: var(--text-light);
    opacity: 0.5;
    transition: var(--transition);
  }
  
  .charity-mission:hover svg {
    transform: scale(1.1);
    opacity: 1;
    color: var(--primary-color);
  }
  
  .charity-mission p {
    margin: 0;
    padding-left: 1.5rem;
    font-size: 0.95rem;
    line-height: 1.6;
    color: var(--text-color);
  }
  
  .charity-metrics {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.25rem;
    margin-top: 0.5rem;
  }
  
  .metric {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    padding: 1.25rem;
    background-color: var(--background-color);
    border-radius: 12px;
    transition: var(--transition);
  }
  
  .metric:hover {
    transform: translateY(-2px);
    background-color: var(--blue-light);
  }
  
  .metric-icon {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
    transition: var(--transition);
  }
  
  .metric:hover .metric-icon {
    transform: scale(1.1);
  }
  
  .metric-data {
    display: flex;
    flex-direction: column;
    gap: 0.375rem;
  }
  
  .metric-data span {
    font-size: 0.875rem;
    color: var(--text-light);
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }
  
  .metric-data strong {
    font-size: 1.5rem;
    color: var(--secondary-color);
    font-weight: 700;
  }
  
  /* Button Styles */
  .btn {
    display: inline-flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.875rem 1.5rem;
    border-radius: 12px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: var(--transition);
    border: none;
    outline: none;
  }
  
  .btn-primary {
    background-color: var(--primary-color);
    color: white;
    box-shadow: 0 4px 12px rgba(52, 152, 219, 0.2);
  }
  
  .btn-primary:hover {
    background-color: #2980b9;
    transform: translateY(-2px);
    box-shadow: 0 6px 16px rgba(52, 152, 219, 0.3);
  }
  
  .btn-link {
    color: var(--primary-color);
    background: transparent;
    padding: 0.5rem 1rem;
    font-size: 0.95rem;
    border-radius: 8px;
  }
  
  .btn-link:hover {
    background-color: var(--blue-light);
  }
  
  /* Custom Scrollbar */
  .charity-cards::-webkit-scrollbar {
    width: 6px;
  }
  
  .charity-cards::-webkit-scrollbar-track {
    background: var(--background-color);
    border-radius: 3px;
  }
  
  .charity-cards::-webkit-scrollbar-thumb {
    background: var(--border-color);
    border-radius: 3px;
    transition: var(--transition);
  }
  
  .charity-cards::-webkit-scrollbar-thumb:hover {
    background: var(--text-light);
  }
  
  /* Animations */
  @keyframes fadeIn {
    from { 
      opacity: 0; 
      transform: translateY(10px); 
    }
    to { 
      opacity: 1; 
      transform: translateY(0); 
    }
  }
  
  @keyframes slideUp {
    from { 
      opacity: 0; 
      transform: translateY(20px); 
    }
    to { 
      opacity: 1; 
      transform: translateY(0); 
    }
  }
  
  @keyframes slideDown {
    from { 
      opacity: 0; 
      transform: translateY(-20px); 
    }
    to { 
      opacity: 1; 
      transform: translateY(0); 
    }
  }
  
  @keyframes slideIn {
    from { 
      opacity: 0; 
      transform: translateX(-20px); 
    }
    to { 
      opacity: 1; 
      transform: translateX(0); 
    }
  }
  
  /* Responsive Design */
  @media (max-width: 1400px) {
    :root {
        --container-max-width: 1100px;
      }
      
      .stat-content p {
        font-size: 2.25rem;
      }
  }
  
  @media (max-width: 1200px) {
      :root {
        --container-max-width: 900px;
      }
      
      .impact-stats {
        grid-template-columns: repeat(2, 1fr);
      }
  
      .impact-data-grid {
        grid-template-columns: 1fr;
        gap: 1.5rem;
      }
  
      .impact-charities-container {
        min-height: 400px;
      }
  
      .stat-content p {
        font-size: 2rem;
      }
  
      .charity-metrics {
        grid-template-columns: repeat(2, 1fr);
      }
  }
  
  @media (max-width: 992px) {
      .impact-dashboard-container {
        margin-left: 0;
        padding: 1rem;
      }
  
      .impact-header h1 {
        font-size: 2rem;
      }
  
      .charity-impact-card {
        padding: 1.5rem;
      }
  
      .metric {
        padding: 1rem;
      }
  
      .metric-data strong {
        font-size: 1.25rem;
      }
  }
  
  @media (max-width: 768px) {
  
      .impact-stats {
        grid-template-columns: 1fr;
      }
  
      .stat-content p {
        font-size: 1.75rem;
      }
  
      .charity-metrics {
        grid-template-columns: 1fr;
      }
  
      .charity-logo {
        width: 48px;
        height: 48px;
      }
  
      .charity-title h4 {
        font-size: 1.1rem;
      }
  
      .charity-mission {
        padding: 1rem;
      }
  
      .charity-mission p {
        font-size: 0.9rem;
      }
  
      .btn {
        width: 100%;
        justify-content: center;
      }
  }
  
  @media (max-width: 480px) {
      .impact-header h1 {
        font-size: 1.75rem;
      }
  
      .impact-stat-card {
        padding: 1.25rem;
      }
  
      .stat-icon {
        width: 40px;
        height: 40px;
        font-size: 1.25rem;
      }
  
      .charity-impact-card {
        padding: 1.25rem;
      }
  
      .metric-icon {
        width: 32px;
        height: 32px;
        font-size: 1rem;
      }
  }
  
  /* Print Styles */
  @media print {
      .impact-dashboard-container {
        margin-left: 0;
        padding: 0;
        background-color: white;
      }
  
      .impact-stats {
        page-break-inside: avoid;
      }
  
      .impact-data-grid {
        page-break-inside: avoid;
      }
  
      .charity-impact-card {
        break-inside: avoid;
        box-shadow: none;
        border: 1px solid var(--border-color);
      }
  
      .impact-charities-container {
        box-shadow: none;
        border: 1px solid var(--border-color);
      }
  
      .btn,
      .btn-link {
        display: none;
      }
  }
  
  /* Dark Mode Support */
  @media (prefers-color-scheme: dark) {
      :root {
        --background-color: #1a1e23;
        --card-background: #2d3439;
        --text-color: #e1e8ed;
        --text-light: #a1a8ad;
        --border-color: #3d444a;
        --shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
        --blue-light: rgba(52, 152, 219, 0.15);
        --green-light: rgba(46, 204, 113, 0.15);
        --purple-light: rgba(155, 89, 182, 0.15);
        --emerald-light: rgba(26, 188, 156, 0.15);
      }
  
      .charity-mission {
        background-color: rgba(0, 0, 0, 0.2);
      }
  
      .metric {
        background-color: rgba(0, 0, 0, 0.2);
      }
  
      .charity-rank {
        background-color: rgba(52, 152, 219, 0.2);
      }
  }
  
  /* Accessibility */
  @media (prefers-reduced-motion: reduce) {
      * {
        animation: none !important;
        transition: none !important;
      }
  }
  
  /* Focus States */
  .btn:focus,
  .btn-link:focus,
  .charity-impact-card:focus-within {
      outline: 2px solid var(--primary-color);
      outline-offset: 2px;
  }
  
  /* Loading States */
  .impact-dashboard-container.loading {
      opacity: 0.7;
      pointer-events: none;
  }
  
  .impact-stats.loading .impact-stat-card,
  .charity-cards.loading .charity-impact-card {
      position: relative;
      overflow: hidden;
  }
  
  .impact-stats.loading .impact-stat-card::before,
  .charity-cards.loading .charity-impact-card::before {
      content: '';
      position: absolute;
      top: 0;
      left: -100%;
      width: 200%;
      height: 100%;
      background: linear-gradient(
          90deg,
          transparent 25%,
          rgba(255, 255, 255, 0.2) 50%,
          transparent 75%
      );
      animation: loading 1.5s infinite linear;
  }
  
  @keyframes loading {
      from { transform: translateX(-100%); }
      to { transform: translateX(100%); }
  }