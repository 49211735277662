:root {
  --primary-color: #3498db;
  --secondary-color: #2c3e50;
  --success-color: #28a745;
  --danger-color: #dc3545;
  --warning-color: #ffa500;
  --info-color: #17a2b8;
  --light-gray: #f8f9fa;
  --border-color: #e1e4e8;
  --text-color: #333;
  --white: #ffffff;
  --sidebar-width: 250px;
  --header-height: 60px;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  display: flex;
  flex: 1;
}

.purchases-container {
  flex: 1;
  padding: 2rem;
  background-color: var(--light-gray);
  margin-left: var(--sidebar-width);
  min-height: calc(100vh - var(--header-height));
  overflow-y: auto;
}

h1, h2, h3 {
  color: var(--secondary-color);
}

h1 {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
  font-size: 1.75rem;
  border-bottom: 2px solid var(--primary-color);
  padding-bottom: 0.5rem;
}

/* Filters */
.filters {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 2rem;
  background-color: var(--white);
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.filter-group {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.filter-group select,
.filter-group input[type="text"],
.filter-group input[type="date"] {
  padding: 0.5rem;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 0.875rem;
}

.filter-group .icon {
  color: var(--secondary-color);
}

.btn-reset {
  background-color: var(--secondary-color);
  color: var(--white);
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.btn-reset:hover {
  background-color: #34495e;
}

/* Table */
.purchase-orders-table-container {
  overflow-x: auto;
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.purchase-orders-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.purchase-orders-table th,
.purchase-orders-table td {
  padding: 1rem;
  text-align: left;
  border-bottom: 1px solid var(--border-color);
}

.purchase-orders-table th {
  background-color: var(--primary-color);
  color: var(--white);
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.875rem;
  letter-spacing: 0.05em;
  cursor: pointer;
  transition: background-color 0.2s;
}

.purchase-orders-table th:hover {
  background-color: #2980b9;
}

.purchase-orders-table tr:last-child td {
  border-bottom: none;
}

.purchase-orders-table tr:hover {
  background-color: rgba(52, 152, 219, 0.05);
}

/* Status Indicator */
.status-indicator {
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0.25rem 0.75rem;  /* Increased horizontal padding */
  border-radius: 12px;
  font-size: 0.75rem;
  font-weight: 500;
  color: var(--white);
  min-width: 90px;  /* Added minimum width */
  justify-content: center;  /* Center the content */
  white-space: nowrap;  /* Prevent text wrapping */
}

.status-indicator svg {
  font-size: 1em;
  margin-right: 0.25rem;
  flex-shrink: 0;  /* Prevent icon from shrinking */
}

.status-indicator[data-status="pending"] {
  background-color: var(--warning-color);
  color: var(--white);
}

.status-indicator[data-status="approved"] {
  background-color: var(--success-color);
  color: var(--white);
}

/* Buttons */
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.btn svg {
  margin-right: 0.25rem;
}

.btn-view {
  background-color: var(--info-color);
  color: var(--white);
}

.btn-sign {
  background-color: var(--primary-color);
  color: var(--white);
}

.btn-clear {
  background-color: var(--secondary-color);
  color: var(--white);
}

.btn-approve {
  background-color: var(--success-color);
  color: var(--white);
  margin-top: 1rem;
}

.btn:hover {
  opacity: 0.9;
  transform: translateY(-1px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* PO Details Container */
.po-details-container {
  display: flex;
  gap: 2rem;
  margin-top: 2rem;
}

.po-details,
.po-pdf-container {
  flex: 1;
  background-color: var(--white);
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.po-details-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.po-details h2 {
  font-size: 1.4rem;
  margin: 0;
}

.btn-close {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: var(--secondary-color);
  cursor: pointer;
  transition: color 0.2s;
}

.btn-close:hover {
  color: var(--danger-color);
}

.po-information,
.approval-information {
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.po-information h3,
.approval-information h3 {
  margin-bottom: 10px;
  font-size: 1.2em;
}

.info-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.info-group strong {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: bold;
  margin-bottom: 5px;
}

.info-group span {
  padding-left: 1.5rem;
}

.signature-container {
  margin-top: 1rem;
}

.signature-canvas {
  border: 2px solid var(--border-color);
  border-radius: 4px;
  width: 100%;
  height: 200px;
  margin-bottom: 1rem;
}

.signature-actions {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.po-pdf-container h3 {
  margin-bottom: 1rem;
}

.po-pdf-container iframe {
  width: 100%;
  height: 600px;
  border: none;
  border-radius: 4px;
}

/* Pagination */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  gap: 1rem;
}

.pagination button {
  padding: 0.5rem 1rem;
  border: 1px solid var(--primary-color);
  background-color: var(--white);
  color: var(--primary-color);
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
}

.pagination button:hover:not(:disabled) {
  background-color: var(--primary-color);
  color: var(--white);
}

.pagination button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.po-details-container {
  animation: fadeIn 0.3s ease-in-out;
}

/* Accessibility */
.btn:focus,
.filter-group select:focus,
.filter-group input:focus {
  outline: 2px solid var(--primary-color);
  outline-offset: 2px;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .purchases-container {
    padding: 1rem;
  }

  .filters {
    flex-direction: column;
  }

  .filter-group {
    width: 100%;
  }

  .po-details-container {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .purchase-orders-table th,
  .purchase-orders-table td {
    padding: 0.75rem 0.5rem;
  }

  .signature-actions {
    flex-direction: column;
  }

  .signature-actions .btn {
    width: 100%;
  }

  /* Responsive table layout */
  .purchase-orders-table,
  .purchase-orders-table thead,
  .purchase-orders-table tbody,
  .purchase-orders-table th,
  .purchase-orders-table td,
  .purchase-orders-table tr {
    display: block;
  }

  .purchase-orders-table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .purchase-orders-table tr {
    margin-bottom: 1rem;
    border: 1px solid var(--border-color);
    border-radius: 4px;
  }

  .purchase-orders-table td {
    border: none;
    position: relative;
    padding-left: 50%;
    text-align: right;
  }

  .purchase-orders-table td:before {
    content: attr(data-label);
    position: absolute;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
    font-weight: bold;
  }

  .po-pdf-container iframe {
    height: 400px;
  }
}