/* CharitySelector.css */

/* ====================
   Modal Container
   ==================== */
   .charity-selector {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 900px;
    max-height: 85vh;
    background-color: #ffffff;
    border-radius: 16px;
    box-shadow: 0 12px 40px rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: column;
    z-index: 1000;
    animation: modalEntry 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  /* ====================
     Header Section
     ==================== */
  .charity-selector-header {
    position: sticky;
    top: 0;
    padding: 24px;
    border-bottom: 1px solid #f1f5f9;
    background-color: #ffffff;
    border-radius: 16px 16px 0 0;
    display: flex;
    align-items: center;
    gap: 16px;
    z-index: 10;
  }
  
  .search-container {
    flex: 1;
    max-width: 600px;
  }
  
  .search-box {
    position: relative;
    width: 100%;
  }
  
  .search-icon {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    color: #64748b;
    font-size: 18px;
  }
  
  .search-input {
    width: 100%;
    height: 48px;
    padding: 0 16px 0 48px;
    border: 2px solid #e2e8f0;
    border-radius: 12px;
    font-size: 16px;
    color: #0f172a;
    background-color: #f8fafc;
    transition: all 0.2s ease;
  }
  
  .search-input:hover {
    border-color: #cbd5e1;
    background-color: #ffffff;
  }
  
  .search-input:focus {
    outline: none;
    border-color: #3b82f6;
    background-color: #ffffff;
    box-shadow: 0 0 0 4px rgba(59, 130, 246, 0.1);
  }
  
  .search-input::placeholder {
    color: #94a3b8;
  }
  
  .close-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 0;
    border: none;
    border-radius: 10px;
    background-color: #f1f5f9;
    color: #64748b;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .close-button:hover {
    background-color: #e2e8f0;
    color: #475569;
  }
  
  /* ====================
     Charities List
     ==================== */
  .charities-list {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
  }
  
  .charity-item {
    background-color: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 12px;
    margin-bottom: 16px;
    transition: all 0.2s ease;
  }
  
  .charity-item:hover {
    border-color: #cbd5e1;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  }
  
  .charity-header {
    padding: 16px 20px;
    background-color: #f8fafc;
    border-bottom: 1px solid #e2e8f0;
    border-radius: 12px 12px 0 0;
  }
  
  .charity-header h3 {
    margin: 0;
    color: #0f172a;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.4;
  }
  
  /* ====================
     Locations List
     ==================== */
  .locations-list {
    padding: 8px;
  }
  
  .location-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    margin: 4px;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.15s ease;
  }
  
  .location-item:hover {
    background-color: #f8fafc;
  }
  
  .location-item.selected {
    background-color: #f0f7ff;
    border: 1px solid #bfdbfe;
  }
  
  .location-info {
    display: flex;
    align-items: center;
    gap: 14px;
    flex: 1;
  }
  
  .location-icon {
    color: #3b82f6;
    font-size: 18px;
    flex-shrink: 0;
  }
  
  .location-details {
    flex: 1;
  }
  
  .address {
    color: #1e293b;
    font-size: 15px;
    font-weight: 500;
    margin: 0 0 4px 0;
  }
  
  .capacity-info {
    color: #64748b;
    font-size: 14px;
    margin: 0;
  }
  
  .selected-icon {
    color: #2563eb;
    font-size: 18px;
  }
  
  /* ====================
     States
     ==================== */
  /* Loading State */
  .charity-selector.loading {
    min-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  
  .spinner {
    color: #3b82f6;
    font-size: 32px;
    animation: spin 1s linear infinite;
  }
  
  /* Error State */
  .charity-selector.error {
    min-height: 400px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 20px;
  }
  
  .charity-selector.error p {
    color: #ef4444;
    font-size: 16px;
    margin: 0;
  }
  
  .charity-selector.error button {
    padding: 12px 24px;
    border: none;
    border-radius: 8px;
    background-color: #ef4444;
    color: #ffffff;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .charity-selector.error button:hover {
    background-color: #dc2626;
  }
  
  /* No Results State */
  .no-results {
    padding: 60px 20px;
    text-align: center;
    color: #64748b;
  }
  
  .no-results p {
    margin: 0 0 20px 0;
    font-size: 16px;
  }
  
  .no-results button {
    padding: 12px 24px;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    background-color: #f8fafc;
    color: #0f172a;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .no-results button:hover {
    background-color: #f1f5f9;
    border-color: #cbd5e1;
  }
  
  /* ====================
     Animations
     ==================== */
  @keyframes modalEntry {
    from {
      opacity: 0;
      transform: translate(-50%, -45%);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  /* ====================
     Scrollbar Styling
     ==================== */
  .charities-list::-webkit-scrollbar {
    width: 8px;
  }
  
  .charities-list::-webkit-scrollbar-track {
    background-color: #f8fafc;
    border-radius: 4px;
  }
  
  .charities-list::-webkit-scrollbar-thumb {
    background-color: #cbd5e1;
    border-radius: 4px;
  }
  
  .charities-list::-webkit-scrollbar-thumb:hover {
    background-color: #94a3b8;
  }
  
  /* ====================
     Responsive Design
     ==================== */
  @media (max-width: 640px) {
    .charity-selector {
      width: 95%;
      max-height: 90vh;
    }
  
    .charity-selector-header {
      padding: 16px;
    }
  
    .search-input {
      height: 44px;
      font-size: 15px;
    }
  
    .close-button {
      width: 36px;
      height: 36px;
    }
  
    .charities-list {
      padding: 16px;
    }
  
    .charity-header {
      padding: 14px 16px;
    }
  
    .location-item {
      padding: 14px;
    }
  
    .address {
      font-size: 14px;
    }
  
    .capacity-info {
      font-size: 13px;
    }
  }